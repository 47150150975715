import {
	createContext,
	defineWompo,
	html,
	useCallback,
	useEffect,
	useExposed,
	useMemo,
	useRef,
	useState,
	WompoElement,
	WompoProps,
} from 'wompo';
import Dropper from './dropper.js';
import { BuilderContextI, HistoryObject, Widget, WidgetItem } from './builder.js';
import useElements from './hooks/useElements.js';
import useWidgetsCss from './hooks/useWidgetsCss.js';
import useWidgetsScripts from './hooks/useWidgetsScripts.js';
import RenderMode from './render-mode.js';
import { handleStyleObj } from './getHtml.js';
import Menu, { MenuElement } from 'emcomp/library/components/menu/menu.js';
import MenuItem from 'emcomp/library/components/menu/menu-item.js';
import useWidgetsEditingCss from './hooks/useWidgetsEditingCss.js';

interface Element {
	id: string;
	props: any;
}

export interface CanvasProps extends WompoProps {
	items: Element[];
	setItems: (newValue: Element[] | ((oldValue: Element[]) => Element[])) => void;
	builderContext: BuilderContextI;
	historyPushedCb: (operation: HistoryObject) => void;
	preview: boolean;
	attached?: boolean;
}

export interface CanvasElement extends WompoElement<CanvasProps> {
	canvas?: HTMLDivElement;
	goBackHistory: (operation: HistoryObject) => [number, number];
	goForwardHistory: (operation: HistoryObject) => [number, number];
}

const insertAt = (
	coords: string[],
	items: any[],
	index: number = 0,
	widget: Widget,
	data: any
): Element[] => {
	if (index === coords.length - 1) {
		return items.toSpliced(parseInt(coords[index]), 0, structuredClone(data));
	}
	return items.map((item, i) => {
		if (i === parseInt(coords[index])) {
			return {
				...item,
				props: {
					...item.props,
					items: insertAt(coords, item.props.items, index + 1, widget, data),
				},
			};
		}
		return item;
	});
};

const removeAt = (
	coords: string[],
	items: any[],
	index: number = 0,
	removed: any[] = []
): [Element[], Element] => {
	if (index === coords.length - 1) {
		return [
			items.filter((data, i) => {
				const toKeep = i !== parseInt(coords[index]);
				if (!toKeep) removed.push(structuredClone(data));
				return toKeep;
			}),
			removed[0],
		];
	}
	return [
		items.map((item, i) => {
			if (i === parseInt(coords[index])) {
				return {
					...item,
					props: {
						...item.props,
						items: removeAt(coords, item.props.items, index + 1, removed)[0],
					},
				};
			}
			return item;
		}),
		removed[0],
	];
};

const duplicateAt = (
	coords: string[],
	items: any[],
	index: number = 0,
	added: Element[] = []
): [Element[], Element] => {
	if (index === coords.length - 1) {
		const toCopy = structuredClone(items[parseInt(coords[index])]);
		added.push(toCopy);
		return [items.toSpliced(parseInt(coords[index]), 0, toCopy), structuredClone(toCopy)];
	}
	return [
		items.map((item, i) => {
			if (i === parseInt(coords[index])) {
				return {
					...item,
					props: {
						...item.props,
						items: duplicateAt(coords, item.props.items, index + 1, added)[0],
					},
				};
			}
			return item;
		}),
		added[0],
	];
};

const editAt = (
	coords: string[],
	items: any[],
	index: number = 0,
	data: any,
	edited: Element[] = []
): [Element[], Element] => {
	if (index === coords.length - 1) {
		return [
			items.map((oldData, i) => {
				if (i === parseInt(coords[index])) {
					edited.push(structuredClone(oldData));
					return {
						...oldData,
						...data,
					};
				}
				return oldData;
			}),
			edited[0],
		];
	}
	return [
		items.map((item, i) => {
			if (i === parseInt(coords[index])) {
				return {
					...item,
					props: {
						...item.props,
						items: editAt(coords, item.props.items, index + 1, data, edited)[0],
					},
				};
			}
			return item;
		}),
		edited[0],
	];
};

interface CanvasContext {
	insert: (position: string, widget: Widget, data: WidgetItem) => void;
	remove: (position: string) => void;
	duplicate: (position: string) => void;
	edit: (position: string, data: WidgetItem) => void;
	move: (oldPosition: string, newPosition: string, widget: Widget, data: WidgetItem) => void;
	openMenu: (target: HTMLElement, widgetItems: WidgetItem, position: string) => void;
}

export const CanvasContext = createContext<CanvasContext>(null, 'canvas-context');

export default function Canvas({
	items,
	setItems,
	builderContext,
	historyPushedCb,
	preview,
	attached = false,
	styles: s,
}: CanvasProps) {
	const elements = useElements(items, null, false, builderContext);

	const widgetsCss = useWidgetsCss(items, builderContext.registry);
	const widgetsEditingCss = useWidgetsEditingCss(items, builderContext.registry);

	const [copiedStyles, setCopiedStyles] = useState<WidgetItem>(null);
	const menuItemRef = useRef<{ item: WidgetItem; position: string }>(null);
	const elementsMenuRef = useRef<MenuElement>();

	useEffect(() => {
		const widgetsScripts = useWidgetsScripts(items, builderContext.registry);
		const script = (this.ownerDocument as Document).createElement('script');
		script.id = 'widgets-scripts';
		script.type = 'module';
		script.textContent = widgetsScripts;
		(this.ownerDocument as Document).head.appendChild(script);
		return () => {
			script.remove();
		};
	}, [items, preview]);

	const pushHistory = (
		operation: 'insert' | 'remove' | 'edit' | 'move' | 'settings',
		data: any
	) => {
		historyPushedCb({
			operation: operation,
			data: data,
		});
	};

	const canvasRef = useRef<HTMLDivElement>();

	useEffect(() => {
		this.querySelectorAll('a').forEach((link: HTMLAnchorElement) =>
			link.addEventListener('click', (ev: MouseEvent) => ev.preventDefault())
		);
	});

	const goBack = (op: HistoryObject) => {
		if (op) {
			const { data: opData, operation } = op;
			const data = opData.back;
			switch (operation) {
				case 'insert': {
					remove(data.position, true);
					break;
				}
				case 'remove': {
					insert(data.position, data.widget, data.data, true);
					break;
				}
				case 'edit': {
					edit(data.position, data.data, true);
					break;
				}
				case 'move': {
					move(data.oldPosition, data.newPosition, data.widget, data.data, true);
					break;
				}
				case 'settings': {
					break;
				}
				default:
					break;
			}
		}
	};

	const goForward = (op: HistoryObject) => {
		if (op) {
			const { data: opData, operation } = op;
			const data = opData.forward;
			switch (operation) {
				case 'insert': {
					insert(data.position, data.widget, data.data, true);
					break;
				}
				case 'remove': {
					remove(data.position, true);
					break;
				}
				case 'edit': {
					edit(data.position, data.data, true);
					break;
				}
				case 'move': {
					move(data.oldPosition, data.newPosition, data.widget, data.data, true);
					break;
				}
				case 'settings': {
					break;
				}
				default:
					break;
			}
		}
	};

	const move = (
		oldPosition: string,
		newPosition: string,
		widget: Widget,
		data: WidgetItem,
		dontPush?: boolean
	) => {
		setItems((oldItems) => {
			const oldCoords = oldPosition.split('.');
			const newCoords = newPosition.split('.');
			const [withRemoved, removed] = removeAt(oldCoords, oldItems);
			const lastOldCoord = parseInt(oldCoords[oldCoords.length - 1]);
			const newCoordToMove = parseInt(newCoords[oldCoords.length - 1]);
			if (newCoords.length >= oldCoords.length && newCoordToMove > lastOldCoord) {
				newCoords[oldCoords.length - 1] = (
					parseInt(newCoords[oldCoords.length - 1]) - 1
				).toString();
			} else {
				oldCoords[oldCoords.length - 1] = (
					parseInt(oldCoords[oldCoords.length - 1]) + 1
				).toString();
			}
			if (!dontPush && !builderContext.editingGlobal) {
				pushHistory('move', {
					back: {
						oldPosition: newCoords.join('.'),
						newPosition: oldCoords.join('.'),
						widget,
						data: structuredClone(data),
					},
					forward: {
						oldPosition: oldPosition,
						newPosition: newPosition,
						widget,
						data: structuredClone(data),
					},
				});
			}
			const withInserted = insertAt(newCoords, withRemoved, 0, widget, data);
			return withInserted;
		});
	};

	const insert = (position: string, widget: Widget, data: WidgetItem, dontPush?: boolean) => {
		setItems((oldItems: any[]) => {
			const coords = position.split('.');
			const newItems = insertAt(coords, oldItems, 0, widget, data);
			if (!dontPush && !builderContext.editingGlobal) {
				pushHistory('insert', {
					back: { position },
					forward: { position, widget, data: structuredClone(data) },
				});
			}
			return newItems;
		});
	};

	const remove: (position: string, dontPush?: boolean) => void = useCallback(
		(position: string, dontPush?: boolean) => {
			setItems((oldItems) => {
				const coords = position.split('.');
				const removed = removeAt(coords, oldItems);
				if (!dontPush && !builderContext.editingGlobal) {
					pushHistory('remove', {
						back: {
							position,
							data: removed[1],
							widget: builderContext.registry[removed[1].id],
						},
						forward: {
							position,
						},
					});
				}
				return removed[0];
			});
		},
		[builderContext.editingGlobal]
	);

	const duplicate: (position: string) => void = useCallback(
		(position: string, dontPush?: boolean) => {
			setItems((oldItems) => {
				const coords = position.split('.');
				const [newItems, added] = duplicateAt(coords, oldItems);
				if (!dontPush && !builderContext.editingGlobal) {
					pushHistory('insert', {
						back: {
							position,
						},
						forward: {
							position,
							data: added,
							widget: builderContext.registry[added.id],
						},
					});
				}
				return newItems;
			});
		},
		[builderContext.editingGlobal]
	);

	const edit = (position: string, data: WidgetItem, dontPush?: boolean) => {
		setItems((oldItems) => {
			const coords = position.split('.');
			const [newArray, oldData] = editAt(coords, oldItems, 0, data);
			if (!dontPush && !builderContext.editingGlobal) {
				pushHistory('edit', {
					back: {
						position: position,
						data: structuredClone(oldData),
					},
					forward: {
						position: position,
						data: structuredClone(data),
					},
				});
			}
			return newArray;
		});
	};

	const openMenu = (target: HTMLElement, widgetItem: WidgetItem, position: string) => {
		elementsMenuRef.current.open(target);
		menuItemRef.current = {
			item: widgetItem,
			position: position,
		};
	};

	const makeDefaultStyles = () => {
		const newWidget = {
			id: menuItemRef.current.item.id,
			defaultValues: {
				css: {
					style: structuredClone(menuItemRef.current.item.props.css?.style ?? {}),
				},
			},
		};
		builderContext.updateWidget(newWidget);
		elementsMenuRef.current.close();
		menuItemRef.current = null;
	};

	const copyStyles = () => {
		setCopiedStyles(structuredClone(menuItemRef.current.item));
		elementsMenuRef.current.close();
		menuItemRef.current = null;
	};

	const pasteStyles = () => {
		edit(menuItemRef.current.position, {
			...menuItemRef.current.item,
			props: {
				...menuItemRef.current.item.props,
				css: {
					...menuItemRef.current.item.props?.css,
					style: {
						...copiedStyles.props.css.style,
					},
					styleHovered: {
						...copiedStyles.props.css.styleHovered,
					},
				},
			},
		});
		setCopiedStyles(null);
		elementsMenuRef.current.close();
		menuItemRef.current = null;
	};

	const canvasCtx: CanvasContext = {
		insert: insert,
		remove: remove,
		edit: edit,
		move: move,
		duplicate: duplicate,
		openMenu: openMenu,
	};

	const canvasContext = useMemo(() => canvasCtx, [builderContext.editingGlobal]);

	useExposed({
		canvas: canvasRef.current,
		goBackHistory: goBack,
		goForwardHistory: goForward,
	});

	const providerStyles = {
		height: '100%',
		width: '100%',
		display: preview ? 'none' : 'block',
	};

	return html`
		${preview && html`<${RenderMode} items=${items} />`}
		<${CanvasContext.Provider} value=${canvasContext} style=${providerStyles}>
			<div ref=${canvasRef} class="${s.canvas} ${builderContext.treeMode && s.treeMode}">
				${attached ? elements : null}
				${
					!builderContext.layout &&
					!builderContext.editingGlobal &&
					html`<${Dropper} position=${elements.length.toString()} visible />`
				}
			</div>
			<style>
				* {
					box-sizing: border-box;
				}
				${`
					${builderContext.pageSettings?.style}
					body {
						${handleStyleObj(builderContext.pageSettings?.css)}
					}
				`}
			</style>
			<style>${widgetsEditingCss}</style>
			<style>${widgetsCss}</style>
		</${CanvasContext.Provider}>
		<link rel="preconnect" href="https://fonts.googleapis.com" />
		<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
		<${Menu} dense rounded ref=${elementsMenuRef} class=${s.menu}>
			<${MenuItem}
				dense
				@click=${copyStyles}
			>
				${builderContext.i18n.copyStyles}
			</${MenuItem}>
			<${MenuItem}
				dense
				disabled=${!copiedStyles}
				@click=${copiedStyles && pasteStyles}
			>
				${builderContext.i18n.pasteStyles}
			</${MenuItem}>
			<${MenuItem}
				dense
				@click=${makeDefaultStyles}
			>
				${builderContext.i18n.makeDefaultStyles}
			</${MenuItem}>
		</${Menu}>
	`;
}

Canvas.css = `
  :host {
    display: block;
    position: relative;
  }
	:host * {
		box-sizing: border-box;
	}
	.canvas {
		height: 100%;
    width: 100%;
		border-radius: 16px;
		padding: 30px 0;
	}
	.canvas.treeMode {
		max-width: 1300px;
		margin: 0 auto;
	}
	.menu {
		font-family: Montserrat, Arial;
	}
`;

defineWompo(Canvas, { name: 'em-builder-canvas' });
