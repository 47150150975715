import IconButton from 'emcomp/library/components/icon-button/icon-button.js';
import SideSheet, { SideSheetElement } from 'emcomp/library/components/sheets/side-sheet.js';
import {
	createContext,
	defineWompo,
	html,
	RefHook,
	RenderHtml,
	useEffect,
	useExposed,
	useMemo,
	useRef,
	useState,
	WompoElement,
	WompoProps,
} from 'wompo';
import Canvas, { CanvasElement } from './canvas.js';
import WidgetBox from './widget-box.js';
import Form, { FormElement } from 'emcomp/library/components/form/form.js';
import Actions from 'emcomp/library/components/actions/actions.js';
import Button from 'emcomp/library/components/button/button.js';
import type { BuilderElementElement } from './builder-element.js';
import TextInput, { TextInputElement } from 'emcomp/library/components/text-input/text-input.js';
import Tabs, { TabsElement } from 'emcomp/library/components/tabs/tabs.js';
import TabItem from 'emcomp/library/components/tabs/tab-item.js';
import { generateStyles, mainColors } from 'emcomp/library/utilities/colors.js';
import BoxInput from './form-items/box-input.js';
import getHtml, { handleStyleObj } from './getHtml.js';
import HtmlResult from './html-result.js';
import TextArea from 'emcomp/library/components/text-area/text-area.js';
import {
	backIcon,
	closeIcon,
	desktopIcon,
	easelIcon,
	eyeIcon,
	forwardIcon,
	fullscreenIcon,
	phoneIcon,
	plusIcon,
	saveIcon,
	settingsIcon,
	treeIcon,
	tabletIcon,
	structureIcon,
	noStructureIcon,
	warningIcon,
	dataIcon,
	cssIcon,
} from './icons.js';
import Dialog, { DialogElement } from 'emcomp/library/components/dialog/dialog.js';
import Snackbar, { SnackbarElement } from 'emcomp/library/components/snackbar/snackbar.js';
import Select from 'emcomp/library/components/select/select.js';
import { googleFonts, systemFonts } from './fonts.js';
import MenuItem from 'emcomp/library/components/menu/menu-item.js';
import { translations } from './translations.js';
import BuilderColorPicker from './form-items/builder-color-picker.js';
import ElementsCssEditor from './elements-css-editor.js';
import useWidgetsCss from './hooks/useWidgetsCss.js';
import useWidgetsScripts from './hooks/useWidgetsScripts.js';

interface BuilderBackup {
	settings: any;
	widgets: Widget[];
	items: WidgetItem[];
}

interface Layout {
	items: WidgetItem[];
	settings: any;
}

export type WidgetRenderer = {
	(props: any, context: BuilderContextI, view: boolean, position: string): string | RenderHtml;
	scripts?: string;
	css?: string;
};

export type WidgetResult = {
	(props: any, context: BuilderContextI, position: string): string | RenderHtml;
};

export interface BuilderProps extends WompoProps {
	builderId: string;
	tinymcePath: string;
	widgets?: Widget[];
	items?: any[];
	layout?: Layout;
	pageSettings?: any;
	lang?: 'it' | 'en' | typeof translations.en;
	palette?: string[];
	htmlStructure?: string;
	globalWidgetRenderer?: (
		widget: Widget,
		context: BuilderContextI,
		position: string
	) => string | RenderHtml;
	saveCb?: (data: BuilderBackup) => void | Promise<void>;
	closeCb?: (data: BuilderBackup) => void | Promise<void>;
	saveSettingsCb?: (pageSettings: any) => void | Promise<void>;
	saveWidgetCb?: (newGlobal: any, isGlobal: boolean, isNew: boolean) => void | Promise<void>;
	imageSelector?: (currentValue: string) => Promise<{ value: string; name: string }>;
	linkSelector?: (currentValue: string) => Promise<{ value: string; name: string }>;
}

export interface BuilderElement extends WompoElement<BuilderProps> {
	getHtml(fullStructure?: boolean): {
		html: string;
		css: string;
		head: string;
	};
}

export interface BuilderSettings {
	registry: { [widgetId: string]: Widget };
	settings: any;
}

export interface WidgetItem {
	id: string;
	props: any;
}

export interface Widget {
	id: string;
	group?: string;
	structure?: boolean;
	icon: any;
	label: string;
	renderer:
		| WidgetItem
		| ((props: any, context: BuilderContextI, view: boolean, position: string) => any);
	result?: (props: any, context: BuilderContextI, position: string) => any;
	defaultValues: any;
	manualStyles?: boolean;
	editing: RenderHtml | ((widgetProps: any, context: BuilderContextI) => RenderHtml);
	hidden?: boolean;
	accepts?: string[];
	html?: string;
}

export interface BuilderContextI {
	i18n: typeof translations.en;
	lang: string;
	draggingWidget: RefHook<Widget>;
	registry: { [widgetId: string]: Widget };
	pageSettings: any;
	draggingElement: RefHook<BuilderElementElement>;
	isDragging: boolean;
	layout?: Layout;
	view: boolean;
	treeMode: boolean;
	resultMode?: boolean;
	structureMode: boolean;
	editingGlobal: boolean;
	editingItem: BuilderElementElement;
	palette: [string, string, string, string, string, string, string, string, string, string, string];
	iframe: RefHook<HTMLIFrameElement>;
	globalWidgetRenderer?: (
		widget: Widget,
		context: BuilderContextI,
		position: string
	) => string | RenderHtml;
	openItemEditing: (widget: BuilderElementElement) => void;
	startElementDrag: (element: BuilderElementElement) => void;
	endElementDrag: () => void;
	editWidgets: (newWidget: Widget | WidgetItem, widget?: Widget) => Promise<void | string>;
	openCompSheet: () => void;
	updateWidget: (newWidget: Partial<Widget>) => void;
	imageSelector?: (currentValue: string) => Promise<{ value: string; name: string }>;
	linkSelector?: (currentValue: string) => Promise<{ value: string; name: string }>;
}

export interface HistoryObject {
	operation: 'insert' | 'remove' | 'edit' | 'move' | 'settings';
	data: {
		back: any;
		forward: any;
	};
}

export const BuilderContext = createContext<BuilderContextI>(null, 'builder-context');

const availableFonts = [
	...systemFonts.map((font) => {
		return html`<${MenuItem}
		value=${font}
		style=${{ fontFamily: font }}
	>
			${font}
		</${MenuItem}>`;
	}),
	...googleFonts.map((font) => {
		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = `https://fonts.googleapis.com/css2?family=${font.replace(/ /g, '+')}&display=swap`;
		document.head.appendChild(link);
		return html`<${MenuItem}
		value=${font}
		style=${{ fontFamily: font }}
	>
			${font}
		</${MenuItem}>`;
	}),
];

export const buildFullItems = (layout: any[], items: any[]) => {
	return layout.map((item) => {
		if (item.id === 'builder-body') {
			item.props.items = items;
			item._$view = true;
		} else if (item.props.items) {
			item._$view = true;
			item.props.items = buildFullItems(item.props.items, items);
		} else {
			item._$view = true;
		}
		return item;
	});
};

const findBodyItems = (items: any[], found: any[]) => {
	for (const item of items) {
		if (item.id === 'builder-body') found.push(item.props.items);
		else if (item.props.items) findBodyItems(item.props.items, found);
	}
	return found;
};

export const getBodyItems = (layout: any[], items: any[]) => {
	if (!layout) return items;
	return findBodyItems(items, [])[0];
};

const sizes = {
	desktop: '100%',
	tablet: '767px',
	phone: '500px',
};

const defaultPalette = Object.values(mainColors);

/**
 * Renders a Builder that can be used to create any type of User Interface.
 *
 * Accepts the following props:
 * - `builderId`: a string to identify the builder. It's not required.
 * - `widgets`: a list of widgets that the builder can handle.
 * - `items`: the list of initial items in the page.
 * - `render`: if `true`, the final UI will be rendered
 * - `layout`: the layout of the page. The content will go inside the 'builder-body' widget.
 * - `pageSettings`: the initial page settings.
 * - `lang`: the language of the builder.
 * - `saveCb`: an optional callback that gets executed when the "save" button is pressed.
 * - `closeCb`: an optional callback that gets executed when the "close" button is pressed.
 * - `saveSettingsCb`: an optional callback that gets executed when the settings changes.
 * - `saveWidgetCb`: an optional callback that gets executed whenever a widget is added or modified.
 */
export default function Builder({
	builderId,
	tinymcePath,
	widgets: initialWidgets = [],
	items: initialItems = [],
	layout,
	pageSettings: initialPageSettings,
	lang = 'en',
	palette = defaultPalette,
	htmlStructure,
	globalWidgetRenderer,
	saveCb,
	closeCb,
	saveSettingsCb,
	saveWidgetCb,
	imageSelector,
	linkSelector,
	styles: s,
}: BuilderProps) {
	const [widgets, setWidgets] = useState(initialWidgets);
	const [editingItem, setEditingItem] = useState<BuilderElementElement>(null);
	const [isDragging, setIsDragging] = useState(false);
	const [showingPreview, setShowingPreview] = useState(false);
	const [items, setItems] = useState<WidgetItem[]>(
		layout ? buildFullItems(layout?.items, initialItems) : initialItems
	);
	const [pageSettings, setPageSettings] = useState(
		initialPageSettings ?? {
			title: '',
			css: { margin: '8px 8px 8px 8px', fontFamily: 'Bookman', ...layout?.settings?.css },
			style: layout?.settings?.style ?? '',
		}
	);
	const [treeMode, setTreeMode] = useState(false);
	const [editingGlobal, setEditingGlobal] = useState(false);
	const [viewMode, setViewMode] = useState<'desktop' | 'tablet' | 'phone'>('desktop');
	const [fullscreen, setFullscreen] = useState(false);
	const [structureMode, setStructureMode] = useState(false);
	const [widgetsFilter, setWidgetsFilter] = useState('');

	const editinItemsRef = useRef<{ oldItems: WidgetItem[]; editingId: string }>();

	const [canGoBack, setCanGoBack] = useState(false);
	const [canGoForward, setCanGoForward] = useState(false);

	const initialEditingItemPropsRef = useRef<any>(null);
	const initialSettingsRef = useRef<any>(null);

	const widgetsSheetRef = useRef<SideSheetElement>();
	const settingsSheetRef = useRef<SideSheetElement>();
	const editingSheetRef = useRef<SideSheetElement>();
	const editingFormRef = useRef<FormElement>();
	const settingsFormRef = useRef<FormElement>();
	const newWidgetFormRef = useRef<FormElement>();
	const tabsRef = useRef<TabsElement>();
	const cssEditorRef = useRef<any>();

	const draggingWidgetRef = useRef<Widget>(null);
	const draggingElementRef = useRef<BuilderElementElement>(null);

	const iframeRef = useRef<HTMLIFrameElement>();
	const canvasRef = useRef<CanvasElement>();
	const builderContextProviderRef = useRef<WompoElement>();
	const backupDialogRef = useRef<DialogElement>();
	const newWidgetDialogRef = useRef<DialogElement>();
	const snackbarRef = useRef<SnackbarElement>();
	const backupConfirmationRef = useRef(false);

	const newWidgetPromiseRef = useRef<(val: any) => any>(null);

	const historyRef = useRef<HistoryObject[]>([]);
	const historyIndexRef = useRef(0);

	useEffect(() => {
		const bodyStyles = getComputedStyle(document.body);
		const theme = {
			body: palette[0],
			primary: palette[1],
			secondary: palette[2],
			success: palette[3],
			info: palette[4],
			warning: palette[5],
			danger: palette[6],
			light: palette[7],
			dark: palette[8],
			white: palette[9],
			black: palette[10],
		};
		const outerAppTheme = {
			body: bodyStyles.getPropertyValue('--em-color-body'),
			primary: bodyStyles.getPropertyValue('--em-color-primary'),
			secondary: bodyStyles.getPropertyValue('--em-color-secondary'),
			success: bodyStyles.getPropertyValue('--em-color-success'),
			info: bodyStyles.getPropertyValue('--em-color-info'),
			warning: bodyStyles.getPropertyValue('--em-color-warning'),
			danger: bodyStyles.getPropertyValue('--em-color-danger'),
			light: bodyStyles.getPropertyValue('--em-color-light'),
			dark: bodyStyles.getPropertyValue('--em-color-dark'),
			white: bodyStyles.getPropertyValue('--em-color-white'),
			black: bodyStyles.getPropertyValue('--em-color-black'),
		};
		iframeRef.current.onload = () => {
			const iframeDoc = iframeRef.current.contentWindow.document;
			iframeDoc.body.appendChild(builderContextProviderRef.current);
			canvasRef.current.updateProp('attached', true);
			googleFonts.forEach((font) => {
				const link = document.createElement('link');
				link.rel = 'stylesheet';
				link.href = `https://fonts.googleapis.com/css2?family=${font.replace(
					/ /g,
					'+'
				)}&display=swap`;
				iframeDoc.head.appendChild(link);
			});
			iframeDoc.defaultView.addEventListener('keydown', handleKeys);
		};
		const defaultDoc = `
			<!DOCTYPE html>
			<html lang="${lang}">
			<head>
				<meta charset="UTF-8">
				<meta name="viewport" content="width=device-width, initial-scale=1.0">
				<title>Builder Editor Preview</title>
				<script src="${tinymcePath}"></script>
				<style>
					* { box-sizing: border-box; }
					.mce-content-body {
						outline: none;
					}
					${generateStyles(outerAppTheme, false)}
					${generateStyles(theme, true, 'color', false)}
				</style>
			</head>
			<body>
			</body>
			</html>
		`;
		iframeRef.current.srcdoc = defaultDoc;
	}, []);

	useEffect(() => {
		const savedBackup = localStorage.getItem(`em-builder-backup-${builderId}`);
		if (savedBackup) {
			const stringifiedItems = JSON.stringify({
				items: layout ? getBodyItems(layout?.items, items) : items,
				widgets: widgets,
				settings: pageSettings,
			} as BuilderBackup);

			const hasDifferences = stringifiedItems !== savedBackup;
			if (hasDifferences) backupDialogRef.current.open();
			else backupConfirmationRef.current = true;
		} else {
			backupConfirmationRef.current = true;
		}
	}, []);

	const handleKeys = (ev: KeyboardEvent) => {
		const withCtrl = ev.ctrlKey || ev.metaKey;
		if (ev.key === '+') {
			if (withCtrl) {
				ev.preventDefault();
				widgetsSheetRef.current.toggle();
			}
		}
		switch (ev.code) {
			case 'Escape': {
				onClose();
				return;
			}
			case 'KeyS': {
				if (withCtrl) {
					ev.preventDefault();
					onSave();
				}
				return;
			}
			case 'KeyH': {
				if (withCtrl) {
					ev.preventDefault();
					setTreeMode((tree) => !tree);
				}
				return;
			}
			case 'KeyP': {
				if (withCtrl) {
					ev.preventDefault();
					setShowingPreview((preview) => {
						if (!preview) showPreview();
						else return false;
						return true;
					});
				}
				return;
			}
			case 'KeyB': {
				if (withCtrl) {
					ev.preventDefault();
					setStructureMode((structure) => !structure);
				}
				return;
			}
			case 'KeyO': {
				if (withCtrl) {
					ev.preventDefault();
					if (settingsSheetRef.current.isOpen) resetPageSettings();
					else openSettingsSheet();
				}
				return;
			}
			case 'KeyZ': {
				if (withCtrl) {
					ev.preventDefault();
					if (ev.shiftKey) {
						if (
							historyRef.current.length !== 0 &&
							historyIndexRef.current !== historyRef.current.length - 1
						)
							goForwardHistory();
					} else if (historyRef.current.length !== 0 && historyIndexRef.current > -1)
						goBackHistory();
				}
				return;
			}
			default: {
				return;
			}
		}
	};

	useEffect(() => {
		const handleScreenChange = () => {
			if (!document.fullscreenElement) setFullscreen(false);
		};
		window.addEventListener('keydown', handleKeys);
		document.addEventListener('fullscreenchange', handleScreenChange, false);
		document.addEventListener('mozfullscreenchange', handleScreenChange, false);
		document.addEventListener('MSFullscreenChange', handleScreenChange, false);
		document.addEventListener('webkitfullscreenchange', handleScreenChange, false);
		return () => {
			window.removeEventListener('keydown', handleKeys);
			document.removeEventListener('fullscreenchange', handleScreenChange, false);
			document.removeEventListener('mozfullscreenchange', handleScreenChange, false);
			document.removeEventListener('MSFullscreenChange', handleScreenChange, false);
			document.removeEventListener('webkitfullscreenchange', handleScreenChange, false);
		};
	}, []);

	useEffect(() => {
		if (fullscreen) this.requestFullscreen();
		else if (document.fullscreenElement) document.exitFullscreen();
	}, [fullscreen]);

	useEffect(() => {
		if (backupConfirmationRef.current && !editingGlobal) {
			localStorage.setItem(
				`em-builder-backup-${builderId}`,
				JSON.stringify({
					items: getBodyItems(layout?.items, items),
					widgets: widgets,
					settings: pageSettings,
				} as BuilderBackup)
			);
		}
	}, [items, pageSettings, widgets]);

	useEffect(() => {
		const editing = editingItem;
		initialEditingItemPropsRef.current = editing?.widgetProps;
		const formElements = settingsFormRef.current.getFormElements();
		const updateElement = () => {
			const data = settingsFormRef.current.getData();
			setPageSettings(data);
		};
		formElements.forEach((el) => {
			el.addEventListener('input', updateElement);
		});
		settingsFormRef.current.setData(pageSettings);
		return () => {
			formElements.forEach((el) => {
				el.removeEventListener('input', updateElement);
			});
		};
	}, []);

	const onElementsEditingFormInput = (ev: InputEvent) => {
		const form = ev.currentTarget as FormElement;
		const data = form.getData();
		if (editingItem) {
			editingItem.updateProp('widgetProps', {
				...editingItem?.props.widgetProps,
				...data,
				items: editingItem?.props.widgetProps.items,
			});
		}
	};

	useEffect(() => {
		initialEditingItemPropsRef.current = editingItem?.widgetProps;
		const widgetProps = editingItem?.widgetProps;
		editingFormRef.current.setData(widgetProps);
		return () => {
			editingFormRef.current.empty();
			tabsRef.current.goTo(0);
			cssEditorRef.current.setTab('style');
			initialEditingItemPropsRef.current = null;
		};
	}, [editingItem]);

	const i18n: typeof translations.en =
		(typeof lang === 'string'
			? translations[lang as keyof typeof translations] ??
			  (lang as unknown as typeof translations.en)
			: (lang as typeof translations.en)) ?? translations.en;

	const resetEditingData = () => {
		if (initialEditingItemPropsRef.current) {
			editingItem.updateProp('widgetProps', initialEditingItemPropsRef.current);
			editingFormRef.current.setData(initialEditingItemPropsRef.current);
		}
		editingSheetRef.current.close();
		setEditingItem(null);
	};

	const saveEditingData = (close: boolean = true) => {
		const data = editingFormRef.current.getData();
		if (initialEditingItemPropsRef.current && editingItem)
			editingItem.edit({
				id: editingItem.widget.id,
				props: { ...initialEditingItemPropsRef.current, ...data },
			});
		if (close) editingSheetRef.current.close();
		setEditingItem(null);
		if (historyIndexRef.current < 0 || historyRef.current.length === 0) setCanGoBack(false);
		else setCanGoBack(true);
		if (
			historyIndexRef.current === historyRef.current.length - 1 ||
			historyRef.current.length === 0
		)
			setCanGoForward(false);
		else setCanGoForward(true);
	};

	const openCompSheet = () => {
		editingSheetRef.current.close();
		settingsSheetRef.current.close();
		widgetsSheetRef.current.open();
	};

	const openSettingsSheet = () => {
		initialSettingsRef.current = pageSettings;
		editingSheetRef.current.close();
		widgetsSheetRef.current.close();
		settingsSheetRef.current.open();
		settingsFormRef.current.reset();
		settingsFormRef.current.setData(pageSettings);
	};

	const resetPageSettings = () => {
		if (initialSettingsRef.current) {
			setPageSettings(initialSettingsRef.current);
		}
		initialSettingsRef.current = null;
		settingsSheetRef.current.close();
	};

	const savePageSettings = (close: boolean = true) => {
		if (initialSettingsRef.current) {
			if (typeof saveSettingsCb === 'function') saveSettingsCb(pageSettings);
			onHistoryPushed({
				operation: 'settings',
				data: {
					back: {
						data: structuredClone(initialSettingsRef.current),
					},
					forward: {
						data: structuredClone(settingsFormRef.current.getData()),
					},
				},
			});
		}
		initialSettingsRef.current = null;
		if (close) {
			setPageSettings(settingsFormRef.current.getData());
			settingsSheetRef.current.close();
		}
	};

	const openEditingItemSheet = (element: BuilderElementElement) => {
		if (editingItem) editingItem.updateProp('widgetProps', initialEditingItemPropsRef.current);
		if (element !== editingItem) {
			if (editingItem)
				editingItem.edit({
					id: editingItem.widget.id,
					props: { ...initialEditingItemPropsRef.current, ...editingFormRef.current.getData() },
				});
			widgetsSheetRef.current.close();
			settingsSheetRef.current.close();
			editingSheetRef.current.open();
			setEditingItem(element);
		}
		setCanGoBack(false);
		setCanGoForward(false);
	};

	const onDragStartWidget = (widget: Widget) => {
		setIsDragging(true);
		widgetsSheetRef.current.close();
		draggingWidgetRef.current = widget;
	};

	const onDragEndWidget = () => {
		setIsDragging(false);
		draggingWidgetRef.current = null;
	};

	const onDragStartElement = (element: BuilderElementElement) => {
		saveEditingData(true);
		setIsDragging(true);
		draggingElementRef.current = element;
	};

	const onDragEndElement = () => {
		setIsDragging(false);
		draggingElementRef.current = null;
	};

	const editWidgets = async (data: Widget | WidgetItem, parentWidget: Widget) => {
		if (parentWidget) {
			const newWidgetData = (await askNewWidgetName()) as Partial<Widget>;
			newWidgetFormRef.current.reset();
			if (newWidgetData) {
				const contextForHtml = {
					...context,
					layout: null,
				};
				const newId = newWidgetData.label.replace(/\s/g, '-');
				const newWidget: Widget = {
					id: newId,
					structure: false,
					label: newWidgetData.label,
					renderer: data as WidgetItem,
					icon: newWidgetData.icon ?? parentWidget.icon,
					editing: parentWidget.editing,
					defaultValues: {},
					accepts: null,
					html: getHtml(HtmlResult, {
						items: [data],
						context: contextForHtml,
					}),
				};
				setWidgets((oldWidgets) => [...oldWidgets, newWidget]);
				if (typeof saveWidgetCb === 'function') saveWidgetCb(newWidget, true, true);
				snackbarRef.current.updateProp('message', i18n.widgetAdded);
				snackbarRef.current.updateProp('color', 'success');
				snackbarRef.current.alert(2000);
				return newId;
			}
		} else {
			const widget = data as Widget;
			settingsSheetRef.current.close();
			widgetsSheetRef.current.close();
			setEditingGlobal(true);
			setItems((currentItems) => {
				editinItemsRef.current = { oldItems: currentItems, editingId: widget.id };
				return [
					{
						id: (widget.renderer as WidgetItem).id,
						props: (widget.renderer as WidgetItem).props,
					},
				];
			});
		}
	};

	const components = useMemo(() => {
		const filtered = widgets.filter(
			(widget) => !widget.hidden && widget.label.toLowerCase().includes(widgetsFilter)
		);
		const grouped: { [group: string]: Widget[] } = {};
		const globals = [];
		filtered.forEach((widget) => {
			const groupId = widget.group ?? '';
			if (!grouped[groupId]) grouped[groupId] = [];
			if (typeof widget.renderer !== 'function') globals.push(widget);
			else grouped[groupId].push(widget);
		});
		return html`${Object.keys(grouped)
			.sort()
			.map((groupLabel) => {
				const widgets = grouped[groupLabel];
				return html`
					${groupLabel && html`<h4>${groupLabel}</h4>`}
					<div class=${s.components}>
						${widgets.map((widget) => {
							return html`<${WidgetBox}
								icon=${widget.icon}
								label=${widget.label}
								draggable
								@dragstart=${() => onDragStartWidget(widget)}
								@dragend=${onDragEndWidget}
							/>`;
						})}
					</div>
				`;
			})}
		${globals.length > 0 &&
		html`
			<h4>${i18n.globalWidgets}</h4>
			<div class="${s.components} ${s.globals}">
				${globals.map((widget) => {
					return html`<${WidgetBox}
						global
						icon=${widget.icon}
						label=${widget.label}
						draggable
						@dragstart=${() => onDragStartWidget(widget)}
						@dragend=${onDragEndWidget}
					/>`;
				})}
			</div>
		`} `;
	}, [widgets, widgetsFilter]);

	const registry = useMemo(() => {
		const elems: { [widgetId: string]: Widget } = {};
		for (const widget of widgets) {
			elems[widget.id] = widget;
		}
		return elems;
	}, [widgets]);

	const updateWidget = (newWidget: Partial<Widget>) => {
		setWidgets((oldWidgets) =>
			oldWidgets.map((widget) => {
				if (widget.id === newWidget.id) {
					let isGlobal = typeof widget.renderer !== 'function';
					const updated = {
						...widget,
						...newWidget,
						defaultValues: {
							...widget.defaultValues,
							...newWidget.defaultValues,
						},
					};
					if (isGlobal) {
						const contextForHtml = {
							...context,
							layout: null,
						};
						updated.html = getHtml(HtmlResult, {
							items: [updated.renderer as WidgetItem],
							context: contextForHtml,
						});
					}
					if (typeof saveWidgetCb === 'function') saveWidgetCb(updated, isGlobal, false);
					return updated;
				}
				return widget;
			})
		);
		snackbarRef.current.updateProp('message', i18n.widgetUpdated);
		snackbarRef.current.updateProp('color', 'success');
		snackbarRef.current.alert(2000);
	};

	const pickImage = async (evOrVal: MouseEvent | string, input: TextInputElement) => {
		if (typeof imageSelector === 'function' && typeof evOrVal === 'string') {
			return imageSelector(evOrVal);
		} else if (typeof imageSelector === 'function' && input) {
			const res = await imageSelector(input.value);
			if (res) input.setValue(res.value);
			return res;
		}
	};

	const pickLink = async (evOrVal: MouseEvent | string, input: TextInputElement) => {
		if (typeof linkSelector === 'function' && typeof evOrVal === 'string') {
			return linkSelector(evOrVal);
		} else if (typeof linkSelector === 'function' && input) {
			const res = await linkSelector(input.value);
			if (res) input.setValue(res.value);
			return res;
		}
	};

	const context = useMemo(
		() =>
			({
				i18n: i18n,
				lang: lang,
				layout: layout,
				draggingWidget: draggingWidgetRef,
				registry: registry,
				pageSettings: pageSettings,
				draggingElement: draggingElementRef,
				isDragging: isDragging,
				view: showingPreview,
				treeMode: treeMode,
				structureMode: structureMode,
				editingGlobal: editingGlobal,
				editingItem: editingItem,
				palette: palette,
				iframe: iframeRef,
				globalWidgetRenderer: globalWidgetRenderer,
				editWidgets: editWidgets,
				openItemEditing: openEditingItemSheet,
				startElementDrag: onDragStartElement,
				endElementDrag: onDragEndElement,
				openCompSheet: openCompSheet,
				updateWidget: updateWidget,
				imageSelector: imageSelector ? pickImage : null,
				linkSelector: linkSelector ? pickLink : null,
			} as BuilderContextI),
		[
			registry,
			pageSettings,
			editingItem,
			isDragging,
			treeMode,
			structureMode,
			editingGlobal,
			palette,
		]
	);

	const showPreview = () => {
		widgetsSheetRef.current.close();
		settingsSheetRef.current.close();
		editingSheetRef.current.close();
		setShowingPreview(true);
	};

	const hidePreview = () => {
		setShowingPreview(false);
	};

	const toggleTreeMode = () => {
		setTreeMode(!treeMode);
	};

	const toggleStructure = () => {
		setStructureMode(!structureMode);
	};

	const buildHtml = (full: boolean) => {
		const widgetsCss = useWidgetsCss(items, registry, layout?.items);
		const widgetsScripts = useWidgetsScripts(items, registry, layout?.items);
		const theme = {
			body: palette[0],
			primary: palette[1],
			secondary: palette[2],
			success: palette[3],
			info: palette[4],
			warning: palette[5],
			danger: palette[6],
			light: palette[7],
			dark: palette[8],
			white: palette[9],
			black: palette[10],
		};
		const css = `
			* { box-sizing: border-box; }
			${context.layout ? '' : generateStyles(theme, true, 'color', false)}
			/* PAGE STYLES */
			${context.pageSettings?.style ? context.pageSettings?.style : ''}
			html body {
				${handleStyleObj(context.pageSettings?.css)}
			}
			/* WIDGETS CSS */
			${widgetsCss}
		`;

		const googleFontLink = `https://fonts.googleapis.com/css2?family=${context.pageSettings.css?.fontFamily.replace(
			/\s/g,
			'+'
		)}&display=swap`;

		const headScripts = `
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
			${
				googleFonts.includes(context.pageSettings.css?.fontFamily)
					? `<link rel="stylesheet" href="${googleFontLink}" />`
					: ''
			}
			${context.pageSettings.headScripts ? context.pageSettings.headScripts : ''}
		`;

		const htmlRes = `
			${getHtml(HtmlResult, {
				items: items,
				context: context,
			})}
			${
				widgetsScripts
					? `
						<script>
							${widgetsScripts}
						</script>
					`
					: ''
			}
			${context.pageSettings.footerScripts ? context.pageSettings.footerScripts : ''}
		`;
		return {
			html: htmlRes.replace(/\t/g, '').replace(/\n/g, '').replace(/\s+/g, ' ').trim(),
			css: css.replace(/\t/g, '').replace(/\n/g, '').replace(/\s+/g, ' ').trim(),
			head: headScripts.replace(/\t/g, '').replace(/\n/g, '').trim(),
		};
	};

	const onHistoryPushed = (operation: HistoryObject) => {
		historyRef.current = historyRef.current.slice(0, historyIndexRef.current + 1);
		if (historyRef.current.length === 50) historyRef.current.shift();
		historyRef.current.push(operation);
		historyIndexRef.current = historyRef.current.length - 1;
		setCanGoForward(false);
		setCanGoBack(true);
	};

	const goBackHistory = () => {
		const op = historyRef.current[historyIndexRef.current];
		historyIndexRef.current--;
		if (op.operation !== 'settings') canvasRef.current.goBackHistory(op);
		else setPageSettings(op.data.back.data);
		if (historyIndexRef.current < 0 || historyRef.current.length === 0) setCanGoBack(false);
		else setCanGoBack(true);
		if (
			historyIndexRef.current === historyRef.current.length - 1 ||
			historyRef.current.length === 0
		)
			setCanGoForward(false);
		else setCanGoForward(true);
	};

	const goForwardHistory = () => {
		historyIndexRef.current++;
		const op = historyRef.current[historyIndexRef.current];
		if (op.operation !== 'settings') canvasRef.current.goForwardHistory(op);
		else setPageSettings(op.data.forward.data);
		if (historyIndexRef.current < 0 || historyRef.current.length === 0) setCanGoBack(false);
		else setCanGoBack(true);
		if (
			historyIndexRef.current === historyRef.current.length - 1 ||
			historyRef.current.length === 0
		)
			setCanGoForward(false);
		else setCanGoForward(true);
	};

	const toggleFullscreen = () => {
		if (document.fullscreenElement) setFullscreen(false);
		else setFullscreen(true);
	};

	const closeBackupDialog = (clear?: boolean) => {
		if (clear) {
			localStorage.removeItem(`em-builder-backup-${builderId}`);
		}
		backupConfirmationRef.current = true;
		backupDialogRef.current.close();
	};

	const replaceWithBackup = () => {
		const savedItems = JSON.parse(
			localStorage.getItem(`em-builder-backup-${builderId}`)
		) as BuilderBackup;
		const { items, settings } = savedItems;
		for (const widget of savedItems.widgets) {
			if (registry[widget.id]) {
				widget.renderer = registry[widget.id].renderer;
				widget.editing = registry[widget.id].editing;
			} else if (typeof widget.renderer === 'object' && widget.renderer.id) {
				if (widget.renderer && registry[(widget.renderer as WidgetItem).id]) {
					const savedWidget = registry[(widget.renderer as WidgetItem).id];
					widget.editing = savedWidget.editing;
					widget.icon = savedWidget.icon;
				}
			}
		}
		setWidgets([...savedItems.widgets]);
		if (layout) setItems(structuredClone(buildFullItems(layout?.items, items)));
		else setItems(items);
		setPageSettings(settings);
		closeBackupDialog(false);
	};

	const onClose = () => {
		if (editingGlobal) {
			setItems(editinItemsRef.current.oldItems);
			setEditingGlobal(false);
			editinItemsRef.current = null;
		} else if (typeof closeCb === 'function') {
			const bodyItems = getBodyItems(layout?.items, items);
			const data: BuilderBackup = {
				items: bodyItems,
				widgets: widgets,
				settings: pageSettings,
			};
			closeCb(data);
			localStorage.removeItem(`em-builder-backup-${builderId}`);
		}
	};

	const onSave = () => {
		if (editingGlobal) {
			setWidgets((oldWidgets) =>
				oldWidgets.map((widget) => {
					if (widget.id === editinItemsRef.current.editingId) {
						const contextForHtml = {
							...context,
							layout: null,
						};
						const updated = {
							...widget,
							renderer: items[0],
							html: getHtml(HtmlResult, {
								items: [items[0]],
								context: contextForHtml,
							}),
						};
						if (typeof saveWidgetCb === 'function') saveWidgetCb(updated, true, false);
						return updated;
					}
					return widget;
				})
			);
			setItems(editinItemsRef.current.oldItems);
			setEditingGlobal(false);
			editinItemsRef.current = null;
			snackbarRef.current.updateProp('message', i18n.widgetUpdated);
			snackbarRef.current.updateProp('color', 'success');
			snackbarRef.current.alert(2000);
		} else if (typeof saveCb === 'function') {
			saveCb({
				items: getBodyItems(layout?.items, items),
				widgets: widgets,
				settings: pageSettings,
			});
			localStorage.removeItem(`em-builder-backup-${builderId}`);
		}
	};

	const filterComponents = (ev: InputEvent) => {
		const target = ev.currentTarget as TextInputElement;
		setWidgetsFilter(target.value.toLowerCase());
	};

	const closeNewWidgetDialog = () => {
		if (newWidgetPromiseRef.current) newWidgetPromiseRef.current(null);
		else newWidgetPromiseRef.current = null;
		newWidgetDialogRef.current.close();
	};

	const createWidget = () => {
		if (newWidgetFormRef.current.validate()) {
			newWidgetPromiseRef.current(newWidgetFormRef.current.getData());
			newWidgetPromiseRef.current = null;
			closeNewWidgetDialog();
		}
	};

	const askNewWidgetName = () => {
		saveEditingData(true);
		newWidgetDialogRef.current.open();
		return new Promise((resolve) => {
			newWidgetPromiseRef.current = resolve;
		});
	};

	const validateWidgetName = (value: string) => {
		const lowertcasedValue = value.toLowerCase();
		const newId = lowertcasedValue.replace(/\s/g, '-');
		const isPresent = widgets.find(
			(widget) => widget.id === newId || widget.label.toLocaleLowerCase() === lowertcasedValue
		);
		if (isPresent) return i18n.existingWidget;
		return true;
	};

	const widgetEditing = editingItem?.props.widget?.editing;
	const editingForm =
		typeof widgetEditing === 'function'
			? widgetEditing(editingItem.props.widgetProps, context)
			: widgetEditing;

	useExposed({
		getHtml: buildHtml,
	});

	return html`
    <div class=${s.container}>
			<iframe
				class=${s.iframe}
				ref=${iframeRef}
				style=${{
					width: sizes[viewMode],
					margin: '0 auto',
					transition: 'width .2s ease-in-out',
				}}
			></iframe>
      <${BuilderContext.Provider} value=${context} ref=${builderContextProviderRef}>
				<${Canvas}
					ref=${canvasRef}
					items=${items}
					setItems=${setItems}
					builderContext=${context}
					historyPushedCb=${onHistoryPushed}
					preview=${showingPreview}
				/>
      </${BuilderContext.Provider}>
    </div>
    <div class=${s.actions}>
			${
				(closeCb || editingGlobal) &&
				html`
					<${IconButton}
						class=${showingPreview && s.btnHidden}
						@click=${onClose}
						variant="filled"
						color="dark"
					>
						${closeIcon}
					</${IconButton}>
				`
			}
			<${IconButton}
				class=${showingPreview && s.btnHidden}
				@click=${showPreview}
				variant="filled"
				color="dark"
			>
				${eyeIcon}
      </${IconButton}>
			<${IconButton}
				class=${showingPreview && s.btnHidden}
				@click=${toggleTreeMode}
				variant="filled"
				color="dark"
			>
				${treeMode ? easelIcon : treeIcon}
      </${IconButton}>
			<${IconButton}
				class=${showingPreview && s.btnHidden}
				@click=${goBackHistory}
				disabled=${!canGoBack || editingGlobal}
				variant="filled"
				color="dark"
			>
				${backIcon}
      </${IconButton}>
      <${IconButton}
				@click=${showingPreview ? hidePreview : openCompSheet}
				variant="filled"
				color="primary"
				class="${s.add} ${showingPreview && s.btnPreview}"
			>
        ${plusIcon}
      </${IconButton}>
			<${IconButton}
				class=${showingPreview && s.btnHidden}
				@click=${goForwardHistory}
				disabled=${!canGoForward || editingGlobal}
				variant="filled"
				color="dark"
			>
				${forwardIcon}
      </${IconButton}>
			<${IconButton}
				class=${showingPreview && s.btnHidden}
				@click=${toggleStructure}
				variant="filled"
				color="dark"
			>
				${structureMode ? noStructureIcon : structureIcon}
      </${IconButton}>
      <${IconButton}
				class=${showingPreview && s.btnHidden}
				disabled=${editingGlobal}
				@click=${openSettingsSheet}
				variant="filled"
				color="dark"
			>
        ${settingsIcon}
      </${IconButton}>
			${
				(saveCb || editingGlobal) &&
				html`
					<${IconButton}
						class=${showingPreview && s.btnHidden}
						@click=${onSave}
						variant="filled"
						color="dark"
					>
						${saveIcon}
					</${IconButton}>
				`
			}
    </div>

		<div class=${s.previewBtns}>
			<button class=${fullscreen && s.active} @click=${toggleFullscreen}>
				${fullscreenIcon}
			</button>
			<button class=${viewMode === 'desktop' && s.active} @click=${() => setViewMode('desktop')}>
				${desktopIcon}
			</button>
			<button class=${viewMode === 'tablet' && s.active} @click=${() => setViewMode('tablet')}>
				${tabletIcon}
			</button>
			<button class=${viewMode === 'phone' && s.active} @click=${() => setViewMode('phone')}>
				${phoneIcon}
			</button>
		</div>

    <${SideSheet} title=${i18n.widgets} ref=${widgetsSheetRef} variant="standard" position="left">
			<div class=${s.componentsSheet}>
				<${TextInput}
					autocomplete="off"
					label=${i18n.searchWidgets}
					name="search"
					@input=${filterComponents}
				/>
				<div class=${s.componentsContainer}>
					${components}
				</div>
			</div>
    </${SideSheet}>
		
    <${SideSheet}
			title=${i18n.pageSettings}
			ref=${settingsSheetRef}
			variant="inline"
			onClose=${() => savePageSettings(false)}
		>
			<div class=${s.editingPage}>
				<${Form} ref=${settingsFormRef}>
					<div class=${s.editing}>
						<${TextInput} autocomplete="off" label=${i18n.title} name="title" />
						<${BoxInput} label=${i18n.margin} name="css.margin" />
						<${BoxInput} label=${i18n.padding} name="css.padding" />
						<${Select} label=${i18n.pageFont} name="css.fontFamily" appendMenuTo=${document.body}>
							${availableFonts}
						</${Select}>
						<${BuilderColorPicker}
							iframe=${iframeRef}
							palette=${palette}
							label=${i18n.backgroundColor}
							name="css.backgroundColor"
						/>
						<${BuilderColorPicker}
							iframe=${iframeRef}
							palette=${palette}
							label=${i18n.textColor}
							name="css.color"
						/>
						<${TextArea} autocomplete="off" label=${i18n.headScripts} name="headScripts" />
						<${TextArea} autocomplete="off" label=${i18n.footerScripts} name="footerScripts" />
						<${TextArea} autocomplete="off" label=${i18n.css} name="style" class=${s.cssEditor} />
					</div>
				</${Form}>
				<${Actions}>
					<${Button} color="danger" @click=${resetPageSettings}>${i18n.cancel}</${Button}>
					<${Button} @click=${savePageSettings}>${i18n.ok}</${Button}>
				</${Actions}>
			</div>
    </${SideSheet}>
		
    <${SideSheet}
			title=${i18n.editing}
			ref=${editingSheetRef}
			variant="default"
			onClose=${() => saveEditingData(false)}
		>
      <${Form} ref=${editingFormRef} class=${s.editingForm} @input=${onElementsEditingFormInput}>
				<div class=${s.itemEditor}>
					<${Tabs} ref=${tabsRef} touchDisabled=${true}>
						<${TabItem} label=${i18n.data} icon=${dataIcon}>
							<div class=${s.editing} style="padding: 10px;">
								${editingForm ?? i18n.nothingToEdit}
							</div>
						</${TabItem}>
						<${TabItem} label=${i18n.css} icon=${cssIcon}>
							<div class=${s.cssEditing} style="padding: 10px 0;">
								<${ElementsCssEditor}
									ref=${cssEditorRef}
									editingItem=${editingItem}
									name="css"
									fonts=${availableFonts}
									i18n=${i18n}
									palette=${palette}
									iframe=${iframeRef}
									imageSelector=${imageSelector ? pickImage : null}
								/>
							</div>
						</${TabItem}>
					</${Tabs}>
					<${Actions}>
						<${Button} color="danger" @click=${resetEditingData}>${i18n.cancel}</${Button}>
						<${Button} @click=${saveEditingData}>${i18n.ok}</${Button}>
					</${Actions}>
				</div>
      </${Form}>
    </${SideSheet}>

		<${Dialog} title=${i18n.attention} icon=${warningIcon} ref=${backupDialogRef}>
			${i18n.moreRecentData}. ${i18n.askReplace}
			<${Actions}>
				<${Button} @click=${() => closeBackupDialog(true)} color="danger">${i18n.skip}</${Button}>
				<${Button} @click=${replaceWithBackup}>${i18n.replace}</${Button}>
			</${Actions}>
		</${Dialog}>

		<${Dialog} title=${i18n.newWidget} icon=${plusIcon} ref=${newWidgetDialogRef}>
			${i18n.widgetNameInput}
			<${Form} ref=${newWidgetFormRef} style="margin: 10px 0;">
				<${TextInput} label=${i18n.name} name="label" required validator=${validateWidgetName} />
			</${Form}>
			<${Actions}>
				<${Button} @click=${closeNewWidgetDialog} color="danger">${i18n.cancel}</${Button}>
				<${Button} @click=${createWidget}>${i18n.createWidget}</${Button}>
			</${Actions}>
		</${Dialog}>

		<${Snackbar} ref=${snackbarRef} position="bottom-right" singleLine message="" />
  `;
}

Builder.css = `
	:host {
		background-color: var(--em-color-body);
		color: var(--em-color-on-body);
	}
	.iframe {
		width: 100%;
		height: 100%;
		border: none;
		display: block;
		min-height: 100%;
    width: 100%;
    background-color: #ffffff;
		color: #000000;
		overflow: auto;
	}
  .actions {
    display: flex;
    align-items: end;
    justify-content: center;
    gap: 20px;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
		z-index: 100;
  }
  .actions .add > button {
    width: 60px;
    height: 60px;
  }
	.actions em-icon-button {
		transition: transform .3s ease-in-out;
	}
  
  .container {
    height: 100vh;
    width: 100%;
    overflow: auto;
  }

	.componentsSheet {
		gap: 3px;
    height: calc(100% + 24px);
    display: flex;
    flex-direction: column;
	}
	.componentsContainer {
		height: 100%;
		overflow: auto;
	}
	.componentsContainer h4 {
		font-size: 18px;
    color: var(--em-color-dark-light);
    font-weight: 500;
	}
  .components {
		margin: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 10px;
  }
  .components.globals {
	}

	.btnHidden {
		transform: scale(0);
	}
	.btnPreview {
		transform: rotate(45deg);
	}

	.cssEditor textarea {
		min-height: 300px;
	}

	.rightPanel {
		max-height: 80vh;
		overflow; auto;
	}

	.editingForm, .editingForm form {
		height: 100%;
	}

	.itemEditor {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
	}

	.itemEditor em-tabs {
		height: 100%;
		overflow: auto
	}

	.editing {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: stretch;
	}
	.cssEditing em-collapse > div {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: stretch;
	}
	.cssEditing em-accordion em-accordion-item > div > button {
		background-color: var(--em-color-primary-bg-dark);
	}

	.editingPage {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.editingPage em-form {
		height: 100%;
		overflow: auto;
	}

	.previewBtns {
		z-index: 20;
		position: fixed;
		bottom: 0;
		left: 0;
		background-color: var(--em-color-dark-light);
		color: var(--em-color-on-dark-light);
		display: flex;
	}
	.previewBtns button {
		border: none;
		background-color: transparent;
		cursor: pointer;
		padding: 5px;
		color: var(--em-color-on-dark-light);
	}
	.previewBtns button.active,
	.previewBtns button:hover {
		background-color: var(--em-color-dark);
		color: var(--em-color-on-dark);
	}
`;

defineWompo(Builder, { name: 'em-builder' });
