import MenuItem, { MenuItemElement } from 'emcomp/library/components/menu/menu-item.js';
import Menu, { MenuElement } from 'emcomp/library/components/menu/menu.js';
import Select, { SelectElement } from 'emcomp/library/components/select/select.js';
import TextInput, { TextInputElement } from 'emcomp/library/components/text-input/text-input.js';
import { defineWompo, html, useExposed, useRef, useState, WompoProps } from 'wompo';

interface MeasureInputProps extends WompoProps {
	label: string;
	name: string;
}

export default function MeasureInput({ label, name, styles: s }: MeasureInputProps) {
	const [value, setValue] = useState('');
	const [unit, setUnit] = useState('%');

	const menuRef = useRef<MenuElement>();

	const onUnitChanged = (ev: InputEvent) => {
		const menuItem = ev.currentTarget as MenuItemElement;
		if (menuItem.props.value !== 'px' && parseFloat(value) > 100) setValue('100');
		else if (menuItem.props.value === 'fc') setValue('fit-content');
		setUnit(menuItem.props.value);
		menuRef.current.close();
		Promise.resolve().then(() => {
			this.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
			this.dispatchEvent(new Event('change', { bubbles: true, composed: true }));
		});
	};

	const onValueChanged = (ev: InputEvent) => {
		if (ev.isTrusted) {
			const input = ev.currentTarget as TextInputElement;
			setValue(input.value);
		}
	};

	const openMenu = (ev: MouseEvent) => {
		menuRef.current.open(ev.currentTarget as HTMLButtonElement);
	};

	useExposed({
		_$emForm: true,
		value: value ? (unit === 'fc' ? value : `${value}${unit}`) : '',
		setValue: (newValue: string) => {
			if (newValue === 'fit-content') {
				setValue(newValue);
				setUnit('fc');
			} else if (newValue) {
				const [_, value, unit] = newValue?.match(/(\d*)(.*)/);
				setValue(value ?? '');
				setUnit(unit || '%');
			} else {
				setValue('');
				setUnit('%');
			}
		},
		disabled: false,
		readonly: false,
		name: name,
		required: false,
		validate: () => true,
	});

	const icon = html`<i class=${s.icon}>${unit}</i>`;

	return html` <div class=${s.container}>
    <${TextInput}
      type=${unit === 'fc' ? 'text' : 'number'}
      value=${value}
      label=${label}
      min=${0}
      readonly=${unit === '' || unit === 'fc'}
      max=${unit !== 'px' ? 100 : null}
      trailingIcon=${icon}
      trailingIconClickCallback=${openMenu}
      @input=${onValueChanged}
    />
    <${Menu} dense rounded appendTo=${document.body} ref=${menuRef}>
      <${MenuItem} @click=${onUnitChanged} value="px">px</${MenuItem}>
      <${MenuItem} @click=${onUnitChanged} value="%">%</${MenuItem}>
      <${MenuItem} @click=${onUnitChanged} value="vh">vh</${MenuItem}>
      <${MenuItem} @click=${onUnitChanged} value="vw">vw</${MenuItem}>
      <${MenuItem} @click=${onUnitChanged} value="fc">fit-content</${MenuItem}>
    </${Menu}>
  </div> `;
}

MeasureInput.css = `
  .container {
    display: flex;
  }
  .icon {
    color: var(--em-color-dark);
    font-size: 12px;
  }
`;

defineWompo(MeasureInput, { name: 'em-builder-measure-input' });
