import { html } from 'wompo';
import { Widget, WidgetRenderer } from '../builder.js';
import TextInput from 'emcomp/library/components/text-input/text-input.js';
import { folderIcon } from '../icons.js';
import Select from 'emcomp/library/components/select/select.js';
import MenuItem from 'emcomp/library/components/menu/menu-item.js';

const ImageWidgetRenderer: WidgetRenderer = ({ src, alt, objectFit, elemClass }) => {
	return html`<img
		class="img ${elemClass}"
		src=${src}
		alt=${alt}
		style=${{ objectFit: objectFit }}
	/>`;
};

ImageWidgetRenderer.css = `
  .img {
    width: 100%;
    height: auto;
		display: block;
  }
`;

const icon = html`<svg
	xmlns="http://www.w3.org/2000/svg"
	width="16"
	height="16"
	fill="currentColor"
	class="bi bi-image"
	viewBox="0 0 16 16"
>
	<path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
	<path
		d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z"
	/>
</svg>`;

const ImageWidget: Widget = {
	id: 'image',
	label: 'Image',
	icon: icon,
	renderer: ImageWidgetRenderer,
	defaultValues: {
		src: 'https://images.unsplash.com/photo-1727125893979-5d81a3443ca1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
		alt: 'Image',
		objectFit: 'cover',
	},
	editing: (_, context) => {
		return html`
			<${TextInput}
				name="src"
				label="Image link"
				trailingIcon=${context.imageSelector && folderIcon}
				trailingIconClickCallback=${context.imageSelector}
			/>
			<${TextInput} name="alt" label="Alt text" />
			<${Select} appendMenuTo=${document.body} label="Fit image" name="objectFit">
				<${MenuItem} value="fill">Fill</${MenuItem}>
				<${MenuItem} value="contain">Contain</${MenuItem}>
				<${MenuItem} value="cover">Cover</${MenuItem}>
				<${MenuItem} value="none">None</${MenuItem}>
				<${MenuItem} value="scale-down">Scale down</${MenuItem}>
			</${Select}>
		`;
	},
	manualStyles: true,
};

export default ImageWidget;
