import Slider, { SliderElement } from 'emcomp/library/components/slider/slider.js';
import { defineWompo, html, RefHook, useExposed, useRef, useState, WompoProps } from 'wompo';
import { translations } from '../translations.js';
import ShadowEditor from './shadow-editor.js';
import Form, { FormElement } from 'emcomp/library/components/form/form.js';
import Checkbox from 'emcomp/library/components/checkbox/checkbox.js';

interface FiltersEditorProps extends WompoProps {
	name: string;
	i18n: typeof translations.en;
	iframe: RefHook<HTMLIFrameElement>;
	palette: string[];
}

export default function FiltersEditor({
	name,
	i18n,
	iframe,
	palette,
	styles: s,
}: FiltersEditorProps) {
	const formRef = useRef<FormElement>();

	useExposed({
		_$emForm: true,
		value: () => {
			let stringVal = '';
			const data = formRef.current.getData();
			for (const key in data) {
				if (data[key] !== null && data[key] !== undefined) {
					let val = data[key].toString();
					if (key === 'blur') val += 'px';
					else if (key === 'hue-rotate') val += 'deg';
					else if (key === 'invert') {
						if (data[key]) val = '1';
						else continue;
					}
					stringVal += `${key}(${val}) `;
				}
			}
			return stringVal;
		},
		setValue: (newValue: string) => {
			const filters = (newValue ?? '')?.split(') ');
			const val = {
				'drop-shadow': null,
			};
			for (const filter of filters) {
				const match = (filter + ')').match(/(.*?)\((.*?)\)/);
				if (match) {
					const [_, filterName, filterValue] = match;
					val[filterName] = filterValue;
					if (filterValue.endsWith('px'))
						val[filterName] = filterValue.substring(0, filterValue.length - 2);
					else if (filterValue.endsWith('deg'))
						val[filterName] = filterValue.substring(0, filterValue.length - 3);
					else if (filterName === 'invert') {
						val[filterName] = filterValue ? 'invert(1)' : null;
					}
				}
			}
			formRef.current.empty();
			formRef.current.setData(val);
		},
		disabled: false,
		readonly: false,
		name: name,
		required: false,
		validate: () => formRef.current.validate(),
	});

	return html`
		<${Form} ref=${formRef}>
      <${Slider} label=${i18n.blur} name="blur" min="0" max="20" step="0.5" can-delete />
			<${Slider}
				color="dark"
        label=${i18n.opacity}
        name="opacity"
        min="0"
        max="1"
        step="0.01"
				can-delete
      />
      <${Slider}
				color="warning"
        label=${i18n.brightness}
        name="brightness"
        min="0"
        max="5"
        step="0.01"
				can-delete
      />
      <${Slider}
				color="dark"
        label=${i18n.contrast}
        name="contrast"
        min="0"
        max="5"
        step="0.01"
				can-delete
      />
			<${Slider}
				color="danger"
        label=${i18n.saturate}
        name="saturate"
        min="0"
        max="1"
        step="0.01"
				can-delete
      />
			<${Slider}
				color="warning"
        label=${i18n.sepia}
        name="sepia"
        min="0"
        max="1"
        step="0.01"
				can-delete
      />
      <${ShadowEditor}
        i18n=${i18n}
        iframe=${iframe}
        palette=${palette}
        name="drop-shadow"
      />
			<${Slider}
				color="dark"
        label=${i18n.grayscale}
        name="grayscale"
        min="0"
        max="1"
        step="0.01"
				can-delete
      />
			<${Slider}
				color="secondary"
        label=${i18n.hueRotate}
        name="hue-rotate"
        min="0"
        max="360"
        step="1"
				can-delete
      />
			<${Checkbox} name="invert" label=${i18n.invertColors} />
    </${Form}>
	`;
}

FiltersEditor.css = `
  :host form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

defineWompo(FiltersEditor, { name: 'em-builder-filters-editor' });
