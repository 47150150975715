import { Widget, WidgetItem } from '../builder.js';

const getCssObj = (
	items: WidgetItem[],
	registry: { [id: string]: Widget },
	css: { [id: string]: string } = {}
) => {
	if (!items) return {};
	items.forEach((item) => {
		let renderer = registry[item.id]?.renderer;
		if (typeof renderer !== 'function') renderer = registry[renderer.id]?.renderer;
		if (typeof renderer === 'function' && (renderer as any).editingCss) {
			css[item.id] = (renderer as any).editingCss;
		}
		if (item.props.items) getCssObj(item.props.items, registry, css);

		if (typeof registry[item.id]?.renderer !== 'function') {
			getCssObj((registry[item.id]?.renderer as WidgetItem).props.items, registry, css);
		}
	});
	return css;
};

export default function useWidgetsEditingCss(
	items: WidgetItem[],
	registry: { [id: string]: Widget },
	layout: WidgetItem[] = []
) {
	if (!items) return '';
	const cssObj = getCssObj(items, registry);
	const layoutObj = getCssObj(layout, registry);
	let cssString = '';
	for (const widgetId in cssObj) {
		if (!layoutObj[widgetId]) cssString += cssObj[widgetId];
	}
	return cssString;
}
