import AccordionWidgets from './accordion-widget.js';
import BuilderBody from './builder-body-widget.js';
import ButtonWidget from './button-widget.js';
import CardWidget from './card-widget.js';
import ContainerWidget from './container-widget.js';
import DialogWidget from './dialog-widget.js';
import DivWidget from './div-widget.js';
import DividerWidget from './divider-widget.js';
import FlexWidget from './flex-widget.js';
import GridWidgets from './grid-widgets.js';
import HtmlWidget from './html-widget.js';
import ImageWidget from './image-widget.js';
import TextWidget from './text-widget.js';

const builderWidgets = [
	ContainerWidget,
	DivWidget,
	...GridWidgets,
	HtmlWidget,
	ImageWidget,
	TextWidget,
	BuilderBody,
	ButtonWidget,
	DividerWidget,
	FlexWidget,
	CardWidget,
	...AccordionWidgets,
	DialogWidget,
];

export default builderWidgets;
