export const translations = {
	en: {
		widgets: 'Widgets',
		searchWidgets: 'Search Widgets',
		globalWidgets: 'Global Widgets',
		pageSettings: 'Page Settings',
		title: 'Title',
		padding: 'Padding',
		margin: 'Margin',
		pageFont: 'Page font',
		font: 'Font',
		backgroundColor: 'Background color',
		textColor: 'Text color',
		css: 'CSS',
		ok: 'Ok',
		cancel: 'Cancel',
		top: 'Top',
		bottom: 'Bottom',
		left: 'Left',
		right: 'Right',
		classes: 'Classes',
		id: 'ID',
		attention: 'Attention',
		moreRecentData: 'A more recent version of your data has been retrieved',
		askReplace: 'Do you want to replace the current content with the latest version?',
		skip: 'Skip',
		replace: 'Replace',
		editing: 'Editing',
		newWidget: 'New Widget',
		widgetNameInput: "Insert the new widget's name.",
		name: 'Name',
		createWidget: 'Create Widget',
		existingWidget: 'A widget with the same name already exists',
		widgetAdded: 'Widget added to library',
		widgetUpdated: 'Widget updated',
		data: 'Data',
		nothingToEdit: 'Nothing to edit',
		borderRadius: 'Border Radius',
		containerOptions: 'Container Options',
		textOptions: 'Text Options',
		colors: 'Colors',
		advanced: 'Advanced',
		cssHelp: 'Write the complete selectors',
		textAlign: 'Text Alignment',
		textEditor: 'To edit the content, simply click the text in the preview.',
		backgroundImage: 'Background Image',
		backgroundSize: 'Background Size',
		backgroundPosition: 'Background Position',
		borderColor: 'Border color',
		borderWidth: 'Border width',
		borderStyle: 'Border style',
		copyStyles: "Copy element's styles",
		pasteStyles: 'Paste copied styles',
		makeDefaultStyles: 'Set as default style',
		headScripts: 'Head scripts',
		footerScripts: 'Footer scripts',
		height: 'Height',
		width: 'Width',
		align: 'Align',
		fontSize: 'Font Size',
		opacity: 'Opacity',
		letterSpacing: 'Letter Spacing',
		fontWeight: 'Font Weight',
		filters: 'Filters',
		blur: 'Blur',
		brightness: 'Brightness',
		contrast: 'Contrast',
		shadow: 'Shadow',
		showShadow: 'Show Shadow',
		color: 'Color',
		grayscale: 'Grey Scale',
		hueRotate: 'Hue',
		invertColors: 'Invert colors',
		saturate: 'Saturation',
		sepia: 'Sepia Effect',
		blendMode: 'Blend Mode',
		lineHeight: 'Line Height',
	},
	it: {
		widgets: 'Widgets',
		searchWidgets: 'Cerca Widgets',
		globalWidgets: 'Widgets Globali',
		pageSettings: 'Impostazioni della pagina',
		title: 'Titolo',
		padding: 'Padding',
		margin: 'Margine',
		pageFont: 'Font della pagina',
		font: 'Font',
		backgroundColor: 'Colore di sfondo',
		textColor: 'Colore del testo',
		css: 'CSS',
		ok: 'Ok',
		cancel: 'Annulla',
		top: 'Sopra',
		bottom: 'Sotto',
		left: 'Sinistra',
		right: 'Destra',
		classes: 'Classi',
		id: 'ID',
		attention: 'Attenzione',
		moreRecentData: 'È stata ottenuta una versione dei dati più recente',
		askReplace: "Vuoi rimpiazzare i dati attuali con l'ultima versione?",
		skip: 'Salta',
		replace: 'Rimpiazza',
		editing: 'Modifica',
		newWidget: 'Nuovo Widget',
		widgetNameInput: 'Inserisci il nome del nuovo Widget',
		name: 'Nome',
		createWidget: 'Crea Widget',
		existingWidget: 'Un widget con lo stesso nome esiste già',
		widgetAdded: 'Widget aggiunto alla libreria',
		widgetUpdated: 'Widget aggiornato',
		data: 'Dati',
		nothingToEdit: 'Nulla da modificare',
		borderRadius: 'Arrotondamento del bordo',
		containerOptions: 'Opzioni del contenitore',
		textOptions: 'Opzioni del testo',
		colors: 'Colori',
		advanced: 'Avanzate',
		cssHelp: 'Scrivi i selettori completi',
		textAlign: 'Allineamento del testo',
		textEditor: "Per modificare il contenuto, clicca semplicemente il testo nell'anteprima",
		backgroundImage: 'Immagine di sfondo',
		backgroundSize: "Misura dell'immagine",
		backgroundPosition: "Posizione dell'immagine",
		borderColor: 'Colore del bordo',
		borderWidth: 'Larghezza del bordo',
		borderStyle: 'Stile del bordo',
		copyStyles: "Copia gli stili dell'elemento",
		pasteStyles: 'Incolla gli stili copiati',
		makeDefaultStyles: 'Imposta gli stili come default',
		headScripts: "Scripts nell'head",
		footerScripts: 'Scripts nel footer',
		height: 'Altezza',
		width: 'Larghezza',
		align: 'Allinea',
		fontSize: 'Grandezza del font',
		opacity: 'Opacità',
		letterSpacing: 'Spaziatura tra le lettere',
		fontWeight: 'Peso del font',
		filters: 'Filtri',
		blur: 'Blur',
		brightness: 'Luminosità',
		contrast: 'Contrasto',
		shadow: 'Ombra',
		showShadow: "Mostra l'ombra",
		color: 'Colore',
		grayscale: 'Scala di grigio',
		hueRotate: 'Tono',
		invertColors: 'Inverti colori',
		saturate: 'Saturazione',
		sepia: 'Effetto Seppia',
		blendMode: 'Fusione dello sfondo',
		lineHeight: 'Altezza della linea',
	},
};
