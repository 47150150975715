import { html } from 'wompo';
import { Widget, WidgetRenderer } from '../builder.js';
import RichTextEditor from '../form-items/rich-text-editor.js';

const TextWidgetRenderer: WidgetRenderer = (props, ctx, view, position) => {
	const formattedText = {
		parts: [`<div>${props.content}</div>`],
		values: [],
		_$wompoHtml: true,
	};
	return html`
		${!view &&
		html`<${RichTextEditor} position=${position} props=${props} widgetId="text" context=${ctx} />`}
		${view ? formattedText : html`<div></div>`}
	`;
};

const icon = html`<svg
	xmlns="http://www.w3.org/2000/svg"
	width="16"
	height="16"
	fill="currentColor"
	class="bi bi-body-text"
	viewBox="0 0 16 16"
>
	<path
		fill-rule="evenodd"
		d="M0 .5A.5.5 0 0 1 .5 0h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 0 .5m0 2A.5.5 0 0 1 .5 2h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m9 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-9 2A.5.5 0 0 1 .5 4h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m5 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m7 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-12 2A.5.5 0 0 1 .5 6h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-8 2A.5.5 0 0 1 .5 8h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m7 0a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-7 2a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"
	/>
</svg>`;

const TextWidget: Widget = {
	id: 'text',
	label: 'Text',
	icon: icon,
	renderer: TextWidgetRenderer,
	defaultValues: {
		content: '<p>Lorem Ipsum Dolor Sit Amet...</p>',
	},
	editing: (_, ctx) => {
		return html`${ctx.i18n.textEditor}`;
	},
};

export default TextWidget;
