import BuilderRendered from './builder-rendered.js';
import Builder, {
	BuilderContext,
	BuilderContextI,
	Widget,
	WidgetItem,
	WidgetRenderer,
	BuilderElement,
	BuilderProps,
	BuilderSettings,
	WidgetResult,
} from './builder.js';
import Dropper from './dropper.js';
import BoxInput from './form-items/box-input.js';
import BuilderColorPicker from './form-items/builder-color-picker.js';
import CornerBoxInput from './form-items/corner-box-input.js';
import GridEditor from './form-items/grid-editor.js';
import RichTextEditor from './form-items/rich-text-editor.js';
import useElements from './hooks/useElements.js';
import InactiveBackground from './inactive-background.js';
import { translations } from './translations.js';
import AccordionWidgets from './widgets/accordion-widget.js';
import BuilderBody from './widgets/builder-body-widget.js';
import builderWidgets from './widgets/builderWidgets.js';
import ButtonWidget from './widgets/button-widget.js';
import CardWidget from './widgets/card-widget.js';
import ContainerWidget from './widgets/container-widget.js';
import DialogWidget from './widgets/dialog-widget.js';
import DivWidget from './widgets/div-widget.js';
import DividerWidget from './widgets/divider-widget.js';
import FlexWidget from './widgets/flex-widget.js';
import GridWidgets from './widgets/grid-widgets.js';
import HtmlWidget from './widgets/html-widget.js';
import ImageWidget from './widgets/image-widget.js';
import TextWidget from './widgets/text-widget.js';

export default Builder;

const Widgets = {
	builderWidgets,
	BuilderBody,
	AccordionWidgets,
	ButtonWidget,
	CardWidget,
	ContainerWidget,
	DialogWidget,
	DivWidget,
	DividerWidget,
	FlexWidget,
	GridWidgets,
	HtmlWidget,
	ImageWidget,
	TextWidget,
};

const Hooks = {
	useElements,
};

const Forms = {
	BoxInput,
	ColorPicker: BuilderColorPicker,
	CornerBoxInput,
	GridEditor,
	RichTextEditor,
};

const Components = {
	Dropper,
	InactiveBackground,
};

export {
	BuilderRendered,
	BuilderContext,
	BuilderContextI,
	Widget,
	WidgetItem,
	WidgetRenderer,
	WidgetResult,
	BuilderElement,
	BuilderProps,
	BuilderSettings,
	translations,
	Components,
	Widgets,
	Hooks,
	Forms,
};
