import { defineWompo, html, useMemo, useState } from 'wompo';
import {
	BuilderContext,
	BuilderContextI,
	BuilderProps,
	buildFullItems,
	Widget,
	WidgetItem,
} from './builder.js';
import RenderMode from './render-mode.js';

export default function BuilderRendered({
	widgets = [],
	items: initialItems = [],
	layout,
	pageSettings,
	styles: s,
}: Partial<BuilderProps>) {
	const [items, setItems] = useState<WidgetItem[]>(
		layout ? buildFullItems(layout.items, initialItems) : initialItems
	);

	const registry = useMemo(() => {
		const elems: { [widgetId: string]: Widget } = {};
		for (const widget of widgets) {
			elems[widget.id] = widget;
		}
		return elems;
	}, [widgets]);

	const context = useMemo(
		() =>
			({
				layout: layout,
				registry: registry,
				pageSettings: pageSettings,
				view: true,
				isDragging: false,
			} as Partial<BuilderContextI>),
		[layout, registry, pageSettings]
	);

	return html`<${BuilderContext.Provider} value=${context}>
    <${RenderMode} items=${items} />
  </${BuilderContext.Provider}>`;
}

defineWompo(BuilderRendered, { name: 'em-builder-rendered' });
