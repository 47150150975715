import { defineWompo, html, RefHook, useExposed, useRef, useState, WompoProps } from 'wompo';
import { translations } from '../translations.js';
import TextInput, { TextInputElement } from 'emcomp/library/components/text-input/text-input.js';
import BuilderColorPicker from './builder-color-picker.js';
import Checkbox, { CheckboxElement } from 'emcomp/library/components/checkbox/checkbox.js';
import Collapse from 'emcomp/library/components/collapse/collapse.js';
import Form, { FormElement } from 'emcomp/library/components/form/form.js';

interface ShadowEditorProps extends WompoProps {
	name: string;
	i18n: typeof translations.en;
	iframe: RefHook<HTMLIFrameElement>;
	palette: string[];
}

export default function ShadowEditor({ name, i18n, iframe, palette }: ShadowEditorProps) {
	const formRef = useRef<FormElement>();
	const [isActive, setIsActive] = useState(false);

	const onChangeActive = (ev: InputEvent) =>
		setIsActive((ev.currentTarget as CheckboxElement).value);

	useExposed({
		_$emForm: true,
		value: () => {
			const data = formRef.current.getData();
			if (!isActive) return null;
			return `${data.x}px ${data.y}px ${data.spread}px ${data.color}`;
		},
		setValue: (newValue: string) => {
			if (newValue) {
				const match = newValue.match(/(\d+)px (\d+)px (\d+)px (.*)/);
				if (match) {
					const [_, x, y, spread, color] = match;
					formRef.current.setData({ x, y, spread, color });
					setIsActive(true);
				} else {
					setIsActive(false);
					formRef.current.reset();
				}
			} else {
				setIsActive(false);
				formRef.current.setData({ x: 0, y: 0, spread: 0, color: '#000000' });
			}
		},
		disabled: false,
		readonly: false,
		name: name,
		required: false,
		validate: () => true,
	});

	return html`
		<label>${i18n.shadow}</label>
    <${Form} ref=${formRef}>
      <${Checkbox} value=${isActive} label=${i18n.showShadow} @input=${onChangeActive} />
      <${Collapse} open=${isActive}>
        <${TextInput} type="number" label="x" name="x" />
        <${TextInput} type="number" label="Y" name="y" />
        <${TextInput}
          type="number"
          label="Spread"
          min="0"
          name="spread"
        />
        <${BuilderColorPicker}
          label=${i18n.color}
          name="color"
          iframe=${iframe}
          palette=${palette}
        />
      </${Collapse}>
    </${Form}>
	`;
}

ShadowEditor.css = `
  :host {
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
    border-radius: 16px;
    border: 1px solid var(--em-color-dark);
    padding: 20px;
  }
  :host em-collapse,
  :host form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  :host > label {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: var(--em-color-body);
  }
`;

defineWompo(ShadowEditor, { name: 'em-builder-shadow-editor' });
