import { html } from 'wompo';
import { Widget, WidgetRenderer } from '../builder.js';
import Checkbox from 'emcomp/library/components/checkbox/checkbox.js';
import TextInput from 'emcomp/library/components/text-input/text-input.js';
import Dropper from '../dropper.js';
import useElements from '../hooks/useElements.js';
import RichTextEditor from '../form-items/rich-text-editor.js';
import { folderIcon } from '../icons.js';

const CardWidgetRenderer: WidgetRenderer = (props, ctx, view, position) => {
	const {
		items = [],
		image,
		imageHeight,
		imageRounded,
		title,
		withImage,
		withTitle,
		elemClass,
	} = props;
	const elements = useElements(items, position, view, ctx);

	const formattedTitle = {
		parts: [title],
		values: [],
		_$wompoHtml: true,
	};

	if (ctx.treeMode) {
		return html`
			${elements}
			<${Dropper} style="margin-bottom: 20px;" position=${`${position}.${items.length + 1}`} />
		`;
	}

	return html`
		<div class="card ${elemClass}">
			${withImage &&
			html`
				<div class="card__media ${imageRounded && 'rounded'}" style=${{ height: imageHeight }}>
					<div class="card__media__img" style=${{ backgroundImage: `url(${image})` }}></div>
				</div>
			`}
			<div class="card__content">
				${withTitle &&
				html`
					${!view &&
					html`<${RichTextEditor}
						simple
						selector="h3"
						propName="title"
						position=${position}
						props=${props}
						widgetId="card"
						context=${ctx}
					/>`}
					<h3 class="card__content__title">${formattedTitle}</h3>
				`}
				<div class="card__content__container">
					${elements}
					${!view &&
					html`<${Dropper}
						style="margin-bottom: 20px;"
						position=${`${position}.${items.length + 1}`}
						visible=${items.length === 0}
					/>`}
				</div>
			</div>
		</div>
	`;
};

CardWidgetRenderer.css = `
  .card {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    flex-direction: column;
  }
  .card__media {
    display: block;
    margin: -15px -15px 15px -15px;
  }
  .card__media {
    overflow: hidden;
    position: relative;
    transition: height .5s linear;
  }
  .card__media.rounded {
    border-radius: 15px;
  }

  .card__media__img {
    background-color: var(--color-light);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  .card__content__title {
    font-size: 28px;
    margin: 0;
  }
  .card__content__subtitle {
    margin: 15px 0;
    font-size: 20px;
    font-weight: normal;
    color: color-mix(in srgb, var(--color-on-body) 50%, white 7%);
  }
`;

const icon = html`<svg
	xmlns="http://www.w3.org/2000/svg"
	width="16"
	height="16"
	fill="currentColor"
	class="bi bi-card-heading"
	viewBox="0 0 16 16"
>
	<path
		d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"
	/>
	<path
		d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z"
	/>
</svg>`;

const CardWidget: Widget = {
	id: 'card',
	label: 'Card',
	icon: icon,
	renderer: CardWidgetRenderer,
	structure: true,
	defaultValues: {
		items: [],
		withTitle: true,
		image:
			'https://images.unsplash.com/photo-1719090024495-055990fc4228?q=80&w=3928&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
		imageHeight: 140,
		imageRounded: true,
		title: 'Card Title',
		css: {
			style: {
				backgroundColor: 'var(--color-primary-bg)',
				color: 'var(--color-on-primary-bg)',
				width: '100%',
				height: '100%',
				borderRadius: '20px 20px 20px 20px',
				padding: '15px 15px 15px 15px',
			},
		},
	},
	manualStyles: true,
	editing: (_, ctx) => html`
		<${Checkbox} name="withTitle" label="With Title" />
		<${Checkbox} name="withImage" label="With Image" />
		<${TextInput}
			name="image"
			label="Image link"
			trailingIcon=${ctx.imageSelector && folderIcon}
			trailingIconClickCallback=${ctx.imageSelector}
		/>
		<${TextInput} type="number" name="imageHeight" label="Image height (px)" />
		<${Checkbox} name="imageRounded" label="Rounded image" />
	`,
};

export default CardWidget;
