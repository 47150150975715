import { TextInputElement } from 'emcomp/library/components/text-input/text-input.js';
import { defineWompo, html, useContext, useEffect, useRef, WompoProps } from 'wompo';
import { CanvasContext } from '../canvas.js';
import { BuilderContext, BuilderContextI } from '../builder.js';

export interface RichTextEditorProps extends WompoProps {
	props: any;
	simple?: boolean;
	selector?: string;
	propName?: string;
	position: string;
	widgetId: string;
	context: BuilderContextI;
}

export interface RichTextEditorElement extends TextInputElement {}

const advancedToolbar = `
		undo redo |
		blocks fontsize |
		removeformat bold italic underline strikethrough forecolor backcolor |
		image link unlink |
		alignleft aligncenter alignright alignjustify |
		bullist numlist outdent indent |
		subscript superscript
		| help
	`;

const simpleToolbar = `undo redo | bold italic underline`;

const advancedPlugins = [
	'advlist',
	'autolink',
	'lists',
	'link',
	'image',
	'charmap',
	'preview',
	'anchor',
	'searchreplace',
	'visualblocks',
	'code',
	'fullscreen',
	'insertdatetime',
	'media',
	'table',
	'code',
	'help',
	'wordcount',
	'image',
];

const simplePlugins = ['lists', 'link', 'anchor', 'autolink'];

export default function RichTextEditor({
	props,
	simple,
	selector = 'div',
	propName = 'content',
	position,
	widgetId,
	context,
}: RichTextEditorProps) {
	const { edit } = useContext(CanvasContext);
	const builderContext = useContext(BuilderContext);

	const editedRef = useRef(false);
	const editorRef = useRef<any>(null);
	const propsRef = useRef(props);
	propsRef.current = props;

	const pickMedia = async (callback: (value: any, meta: any) => void, value: string, meta: any) => {
		let selected = null;
		if (meta.filetype == 'image') {
			const cb = context.imageSelector;
			selected = await cb(value);
		} else if (meta.filetype == 'file') {
			const cb = context.linkSelector ?? context.imageSelector;
			selected = await cb(value);
		}
		if (selected) callback(selected.value, { alt: selected.alt, title: selected.title });
		else callback(value, meta);
	};

	useEffect(() => {
		const cssSelector = `.p${position.replace(
			/\./g,
			'-'
		)} em-builder-rich-text-editor + ${selector}`;
		const config = {
			selector: cssSelector,
			menubar: false,
			inline: true,
			apiKey: 'no-api-key',
			plugins: simple ? simplePlugins : advancedPlugins,
			valid_elements: simple ? 'em,span,a,i,strong/b,br' : null,
			toolbar: [simple ? simpleToolbar : advancedToolbar],
			powerpaste_word_import: 'clean',
			powerpaste_html_import: 'clean',
			content_style: ``,
			font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 60pt 72pt',
			file_picker_callback: context.imageSelector ? pickMedia : null,
			init_instance_callback: (editor: any) => {
				editorRef.current = editor;
				editor.setContent(props[propName]);
				editor.on('blur', () => {
					if (editedRef.current)
						edit(position, {
							id: widgetId,
							props: { ...propsRef.current, [propName]: editor.getContent() },
						});
					editedRef.current = false;
				});
				editor.on('input', () => {
					editedRef.current = true;
				});
				editor.on('ExecCommand', () => {
					editedRef.current = true;
				});
			},
		};
		((this as HTMLElement).ownerDocument.defaultView as any).tinymce.init(config);
		return () => {
			((this as HTMLElement).ownerDocument.defaultView as any).tinymce.remove(cssSelector);
		};
	}, [position, widgetId]);

	useEffect(() => {
		if (editedRef.current) {
			if (builderContext.isDragging) editorRef.current.mode.set('readonly');
			else editorRef.current.mode.set('design');
		}
	}, [builderContext.isDragging]);

	useEffect(() => {
		if (editorRef.current) editorRef.current.setContent(props[propName]);
	}, [props[propName]]);

	return html``;
}

RichTextEditor.css = ``;

defineWompo(RichTextEditor, { name: 'em-builder-rich-text-editor' });
