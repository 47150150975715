import { html } from 'wompo';
import useElements from '../hooks/useElements.js';
import GridEditor from '../form-items/grid-editor.js';
import Dropper from '../dropper.js';
import TextInput from 'emcomp/library/components/text-input/text-input.js';
import { Widget, WidgetRenderer } from '../builder.js';

const pxIcon = html`<span style="color: var(--em-color-dark-hovered); font-size: 16px;">px</span>`;

/**
 * Generate CSS classes for each breakpoint in the grid
 * @param screenSize The screen size to generate
 * @returns The generate CSS
 */
function genereateCols(screenSize: 'xs' | 'sm' | 'md' | 'lg' | 'xl') {
	const sizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	const css = `
    ${sizes
			.map(
				(size) => `
          .grid-item[${screenSize}="${size}"]{
            flex: 0 0 auto;
            width: ${(100 * size) / 12}%;
          }
        `
			)
			.join('\n')}
  `;
	return css;
}

const ColumnWidget: WidgetRenderer = ({ items = [] }, context, view, position) => {
	const elements = useElements(items, position, view, context);
	return html`
		${elements}
		${!view &&
		html`<${Dropper}
			position=${`${position}.${items.length + 1}`}
			visible=${items.length === 0}
		/>`}
	`;
};

function GridWidget({ items, rowPadding, colPadding }, context, view, position) {
	const elements = useElements(items, position, view, context);
	const gridStyles = { marginLeft: `-${colPadding}px`, marginRight: `-${colPadding}px` };
	const elemeStyle = { padding: `0 ${colPadding}px ${rowPadding}px ${colPadding}px` };

	return html`
		<div class="grid" style=${gridStyles}>
			${elements.map((element) => {
				return html`
					<div class="grid-item" xs="12" sm="12" md=${12 / elements.length} style=${elemeStyle}>
						${element}
					</div>
				`;
			})}
		</div>
	`;
}

GridWidget.css = `
  .grid {
    display: flex;
    flex-wrap: wrap;
  }
  .grid > * {
    width: 100%;
    max-width: 100%;
  }

  /* Extra Small */
  ${genereateCols('xs')}

  /* Small */
  @media (width >= 576px) {
    ${genereateCols('sm')}
  }
  /* Medium */
  @media (width >= 768px) {
    ${genereateCols('md')}
  }
  /* Large */
  @media (width >= 992px) {
    ${genereateCols('lg')}
  }
  /* Extra Large */
  @media (width >= 1200px) {
    ${genereateCols('xl')}
  }
`;

const GridWidgets: Widget[] = [
	{
		id: 'row',
		icon: html`<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="currentColor"
			class="bi bi-layout-three-columns"
			viewBox="0 0 16 16"
		>
			<path
				d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5zM1.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5H5V1zM10 15V1H6v14zm1 0h3.5a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H11z"
			/>
		</svg>`,
		label: 'Row',
		renderer: GridWidget,
		defaultValues: {
			items: [
				{
					id: 'column-item',
					props: {
						items: [],
					},
				},
				{
					id: 'column-item',
					props: {
						items: [],
					},
				},
				{
					id: 'column-item',
					props: {
						items: [],
					},
				},
			],
			rowPadding: 10,
			colPadding: 10,
		},
		structure: true,
		editing: html`
			<${GridEditor} name="items" label="Structure" />
			<${TextInput}
				type="number"
				name="colPadding"
				label="Margin between columns"
				min="0"
				trailingIcon=${pxIcon}
			/>
			<${TextInput}
				type="number"
				name="rowPadding"
				label="Margin between rows"
				min="0"
				trailingIcon=${pxIcon}
			/>
		`,
	},
	{
		id: 'column-item',
		icon: null,
		label: 'Column',
		renderer: ColumnWidget,
		defaultValues: {
			items: [],
		},
		editing: null,
		hidden: true,
		structure: true,
	},
];

export default GridWidgets;
