import { html } from 'wompo';
import { Widget, WidgetRenderer } from '../builder.js';
import TextInput from 'emcomp/library/components/text-input/text-input.js';
import Select from 'emcomp/library/components/select/select.js';
import MenuItem from 'emcomp/library/components/menu/menu-item.js';
import Dropper from '../dropper.js';
import useElements from '../hooks/useElements.js';
import InactiveBackground from '../inactive-background.js';
import Checkbox from 'emcomp/library/components/checkbox/checkbox.js';

const DialogWidgetRenderer: WidgetRenderer = (
	{ items = [], title, size, triggeredBy, closedBy, backdropCloses, elemClass },
	ctx,
	view,
	position
) => {
	const elements = useElements(items, position, view, ctx);

	if (ctx.treeMode) {
		return html`
			${elements}
			<${Dropper} style="margin-bottom: 20px;" position=${`${position}.${items.length + 1}`} />
		`;
	}

	return html`
		${!view &&
		html`
			<${InactiveBackground} />
			<div style="padding: 20px 0;">
				<div class="dialog editing-dialog ${size} ${elemClass}">
					<div class="dialog__heading">
						${title && html`<h3 class="dialog__title centered">${title}</h3>`}
					</div>
					<div class="dialog__content">
						${elements}
						<${Dropper}
							style="margin-bottom: 20px;"
							position=${`${position}.${items.length + 1}`}
							visible=${items.length === 0}
						/>
					</div>
				</div>
			</div>
		`}
		${view &&
		html`
			<div
				class="dialog ${size} ${elemClass}"
				data-triggered-by=${triggeredBy}
				data-closed-by=${closedBy}
				data-backdrop-closes=${backdropCloses}
			>
				<div class="dialog__heading">
					${title && html`<h3 class="dialog__title centered">${title}</h3>`}
				</div>
				<div class="dialog__content">${elements}</div>
			</div>
			<div class="dialog__backdrop"></div>
		`}
	`;
};

DialogWidgetRenderer.scripts = `
  const openDialog = (dialog) => {
    dialog.classList.remove("closing");
    dialog.ownerDocument.body.style.overflow = 'hidden';
    dialog.classList.add("open");
    dialog.nextElementSibling.classList.add("open");
    dialog.nextElementSibling.classList.remove("closing");
  }

  const closeDialog = (dialog) => {
    dialog.classList.add("closing");
    dialog.ownerDocument.body.style.overflow = 'auto';
    dialog.nextElementSibling.classList.add("closing")
    setTimeout(() => {
      dialog.ownerDocument.body.style.overflow = 'auto';
			dialog.classList.remove("open");
      dialog.classList.remove("closing");
      dialog.nextElementSibling.classList.remove("open");
      dialog.nextElementSibling.classList.remove("closing")
    }, 500);
  }

  const dialogs = document.querySelectorAll(".dialog");
  dialogs.forEach((dialog) => {
    const triggeredBy = dialog.dataset.triggeredBy;
    const closedBy = dialog.dataset.closedBy;
    const backdropCloses = dialog.dataset.backdropCloses;
    if(triggeredBy){
      document.querySelectorAll(triggeredBy).forEach(btn => {
        if(!btn.dialogsAttached)
          btn.dialogsAttached = [];
        if(!btn.dialogsAttached.includes(dialog)){
          btn.dialogsAttached.push(dialog);
          btn.addEventListener('click', () => openDialog(dialog));
        }
      });
      document.querySelectorAll(closedBy).forEach(btn => {
        if(!btn.dialogsToCloseAttached)
          btn.dialogsToCloseAttached = [];
        if(!btn.dialogsToCloseAttached.includes(dialog)){
          btn.dialogsToCloseAttached.push(dialog);
          btn.addEventListener('click', () => closeDialog(dialog));
        }
      });
      if(!dialog.closeAttached && backdropCloses && !backdropCloses === 'false'){
        dialog.closeAttached = true;
        dialog.nextElementSibling.addEventListener('click', () => closeDialog(dialog));
      }
    }
  });
`;

DialogWidgetRenderer.css = `
  .editing-dialog.dialog {
    position: relative;
    opacity: 1;
    transform: scale(1);
    top: unset;
    left: unset;
    margin: 0 auto;
    z-index: 1;
  }

  .dialog {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    position: fixed;
    opacity: 0;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0%) scale(0);
    z-index: 3000;
    min-width: 280px;
    max-width: 90vw;
    max-height: 80vh;
  }
  .dialog.open {
    transform: translate(-50%, -50%) scale(1);
    animation: dialog-smooth-appear .5s ease forwards;
  }
  .dialog.closing {
    animation: dialog-smooth-disappear .5s ease forwards;
  }
  .dialog.small {
    width: 280px;
  }
  .dialog.medium {
    width: 420px;
  }
  .dialog.large {
    width: 560px;
  }

  .dialog__content {
    height: 100%;
    overflow: auto;
  }

  .dialog__title {
    font-size: 24px;
    margin: 0 0 16px 0;
    font-weight: 600;
  }
  .dialog__title.centered {
    text-align: center;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  .dialog__backdrop {
    display: none;
    background-color: #00000040;
		position: fixed;
    top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 2000;
  }
  .dialog__backdrop.transparent {
    background-color: transparent;
  }
  .dialog__backdrop.open {
    display: block;
    animation: dialog-smooth-appear-backdrop .5s ease forwards;
	}
  .dialog__backdrop.open.closing {
    display: block;
    animation: dialog-smooth-disappear-backdrop .5s ease forwards;
  }

  @keyframes dialog-smooth-appear-backdrop  {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dialog-smooth-disappear-backdrop  {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes dialog-smooth-appear {
    0% {
      top: 100%;
      opacity: 0;
    }
    100% {
      top: 50%;
      opacity: 1;
    }
  }
  @keyframes dialog-smooth-disappear {
    0% {
      top: 50%;
      opacity: 1;
    }
    100% {
      top: 100%;
      opacity: 0;
    }
  }
`;

const icon = html`<svg
	xmlns="http://www.w3.org/2000/svg"
	width="16"
	height="16"
	fill="currentColor"
	class="bi bi-chat-square-dots"
	viewBox="0 0 16 16"
>
	<path
		d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
	/>
	<path
		d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
	/>
</svg>`;

const DialogWidget: Widget = {
	id: 'dialog',
	label: 'Dialog',
	icon: icon,
	renderer: DialogWidgetRenderer,
	defaultValues: {
		title: 'Simple dialog!',
		backdropCloses: true,
		items: [],
		size: 'medium',
		css: {
			style: {
				borderRadius: '20px 20px 20px 20px',
				backgroundColor: 'var(--color-body)',
				color: 'var(--color-on-body)',
				padding: '24px 24px 24px 24px',
			},
		},
	},
	structure: true,
	manualStyles: true,
	editing: html`
		<${TextInput} name="triggeredBy" label="Triggered by" required supportingText="CSS Selector" />
		<${TextInput} name="closedBy" label="Closed by" supportingText="CSS Selector" />
		<${TextInput} name="title" label="Title" />
    <${Checkbox} name="backdropCloses" label="Backrop click closes the dialog" />
    <${Select} name="size" label="Dialog Size" appendMenuTo=${document.body}>
      <${MenuItem} value="small">Small</${MenuItem}>
      <${MenuItem} value="medium">Medium</${MenuItem}>
      <${MenuItem} value="large">Large</${MenuItem}>
    </${Select}>
	`,
};

export default DialogWidget;
