import { defineWompo, html, useExposed, useRef, useState, WompoProps } from 'wompo';
import useElements from '../hooks/useElements.js';
import Dropper from '../dropper.js';
import { Widget, WidgetRenderer } from '../builder.js';
import TextInput, { TextInputElement } from 'emcomp/library/components/text-input/text-input.js';

interface AccordionEditorProps extends WompoProps {
	disabled?: boolean;
	readonly?: boolean;
	required?: boolean;
	name?: string;
}

const downIcon = html`
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="18"
		height="18"
		fill="currentColor"
		class="icon"
		viewBox="0 0 16 16"
	>
		<path
			fill-rule="evenodd"
			d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
		/>
	</svg>
`;

function AccordionEditor({ name, disabled, readonly, required }: AccordionEditorProps) {
	const [value, setValue] = useState([]);

	const inputRef = useRef<TextInputElement>();

	const validate = () => {
		return inputRef.current.validate();
	};

	const handleChange = (ev: InputEvent) => {
		const target = ev.currentTarget as TextInputElement;
		const nItems = parseInt(target.value);
		if (nItems > 0 && nItems <= 6) {
			if (value.length > nItems) {
				setValue((oldValue) => {
					return oldValue.filter((_, i) => i < nItems);
				});
			} else {
				const length = nItems - value.length;
				const toAdd = new Array(length).fill(0).map((_, i) => ({
					id: 'accordion-item',
					props: { items: [], title: `Accordion Item #${nItems + i}` },
				}));
				setValue((oldValue) => {
					return [...oldValue, ...toAdd];
				});
			}
		}
	};

	useExposed({
		_$emForm: true,
		value: value,
		setValue: (newValue: any[]) => {
			if (newValue) {
				inputRef.current.setValue(newValue.length.toString());
				setValue(structuredClone(newValue));
			}
		},
		disabled: disabled,
		readonly: readonly,
		name: name,
		required: required,
		validate: validate,
	});

	return html`
		<${TextInput}
			ref=${inputRef}
			type="number"
			min="1"
			value=${value.length}
			label="Number of accordion items"
			@input=${handleChange}
		/>
	`;
}

defineWompo(AccordionEditor, { name: 'em-builder-accordion-editor' });

function AccordionItemWidgetRenderer({ items = [], title, elemClass }, context, view, position) {
	const elements = useElements(items, position, view, context);
	if (context.treeMode)
		return html`
			${elements}
			${!view &&
			html`<${Dropper}
				style="margin-bottom: 20px;"
				position=${`${position}.${items.length + 1}`}
			/>`}
		`;
	return html`
		<div class="accordion__item">
			<button class="accordion__item__head">
				<h3 class="accordion__item__title">${title}</h3>
				${downIcon}
			</button>
			<div class="collapse">
				<div class="accordion__item__content ${elemClass}">
					${elements}
					${!view &&
					html`<${Dropper}
						style="margin-bottom: 20px;"
						position=${`${position}.${items.length + 1}`}
						visible=${items.length === 0}
					/>`}
				</div>
			</div>
		</div>
	`;
}

AccordionItemWidgetRenderer.css = `
  .accordion__item .collapse {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 150ms linear;
  }
  .accordion__item .collapse.open {
    max-height: unset !important;
  }
  
  .accordion__item__head {
    background-color: var(--color-primary-bg);
    color: var(--color-on-primary-bg);
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: border-radius .2s ease-in-out;
  }
  .accordion__item__title {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }
  .accordion__item__head svg[class="icon"] {
    transition: all .2s ease-in-out;
  }
  .accordion__item.open .accordion__item__head {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .accordion__item.open .accordion__item__head svg[class="icon"] {
    transform: rotate(180deg);
  }
  .content {
    padding: 16px;
  }
`;

AccordionItemWidgetRenderer.editingCss = `
	.accordion > em-builder-element > .em-builder-element__structure:not(.em-builder-element__tree) > .em-builder-element__actions {
		bottom: 0;
		left: 1px;
		top: unset !important;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
`;

const AccordionWidgetRenderer: WidgetRenderer = (
	{ items, multiple, elemClass },
	context,
	view,
	position
) => {
	const elements = useElements(items, position, view, context);
	if (context.treeMode) return html`${elements}`;
	return html`<div class="accordion ${elemClass}" multiple=${multiple}>${elements}</div>`;
};

AccordionWidgetRenderer.css = `
  .accordion {
    overflow: hidden;
  }
`;

AccordionWidgetRenderer.scripts = `
  const accordionItems = document.querySelectorAll('.accordion__item__head');
  accordionItems.forEach((accordionItem) => {
    if(accordionItem.eventAttached)
      return;
    accordionItem.eventAttached = true;
    accordionItem.addEventListener('click', (ev) => {
      const parent = ev.currentTarget.parentElement;
      const collapse = parent.querySelector('.collapse');
      const isOpen = parent.classList.contains('open');
      if (isOpen) {
        parent.classList.remove('open');
        collapse.classList.remove('open');
        collapse.style.maxHeight = collapse.style.maxHeight + 'px !important';
        setTimeout(() => {
          collapse.style.maxHeight = 0 + 'px';
        }, 10);
      } else {
        collapse.style.maxHeight = collapse.scrollHeight + 'px';
        setTimeout(() => {
          collapse.classList.add('open');
        }, 150);
        parent.classList.add('open');
      }
    });
  });
`;

const AccordionWidgets: Widget[] = [
	{
		id: 'accordion',
		label: 'Accordion',
		icon: html`<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="currentColor"
			class="bi bi-collection"
			viewBox="0 0 16 16"
		>
			<path
				d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5z"
			/>
		</svg>`,
		renderer: AccordionWidgetRenderer,
		defaultValues: {
			items: [
				{
					id: 'accordion-item',
					props: {
						items: [],
						title: 'Accordion Item #1',
					},
				},
				{
					id: 'accordion-item',
					props: {
						items: [],
						title: 'Accordion Item #2',
					},
				},
				{
					id: 'accordion-item',
					props: {
						items: [],
						title: 'Accordion Item #3',
					},
				},
			],
			css: {
				style: {
					borderRadius: '16px 16px 16px 16px',
					borderColor: 'var(--color-primary-bg)',
					borderStyle: 'solid',
					borderWidth: '1px',
				},
			},
		},
		manualStyles: true,
		structure: true,
		editing: html`<${AccordionEditor} name="items" label="Structure" /> `,
	},
	{
		id: 'accordion-item',
		icon: null,
		label: 'Accordion Item',
		renderer: AccordionItemWidgetRenderer,
		defaultValues: {
			items: [],
			title: 'Accordion Item',
		},
		editing: html`<${TextInput} name="title" label="Title" />`,
		hidden: true,
		structure: true,
		manualStyles: true,
	},
];

export default AccordionWidgets;
